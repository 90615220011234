@charset "UTF-8";
/**
 * Grid Settings
 **/
/**
 * Viewports
 **/
/*
@function verticalSpace($color: false, $viewport: mobile) {
	@if(map-has-key($verticalSpaces, $color) and map-has-key($mqBreakpoints, $viewport)) {
		@return pxToRem(map-get(map-get($verticalSpaces, $color), $viewport));
	} @else {
		@return 0;
	}
}*/
/**
 * Inspired from https://github.com/guardian/sass-mq.git
 */
/**
 * // To enable support for browsers that do not support @media queries,
 * (IE <= 8, Firefox <= 3, Opera <= 9) set $mqResponsive to false
 * Create a separate stylesheet served exclusively to these browsers,
 * meaning @media queries will be rasterized, relying on the cascade itself
 */
/**
 * Name your breakpoints in a way that creates a ubiquitous language
 * across team members. It will improve communication between
 * stakeholders, designers, developers, and testers.
 */
/**
 * Define the breakpoint from the $mqBreakpoints list that should
 * be used as the target width when outputting a static stylesheet
 * (i.e. when $mqResponsive is set to 'false').
 */
/**
 * If you want to display the currently active breakpoint in the top
 * right corner of your site during development, add the breakpoints
 * to this list, ordered by width, e.g. (mobile, tablet, desktop).
 */
/**
 * Media Query mixin
 * Usage:
 * .element {
 *     @include mq($from: mobile) {
 *         color: red;
 *     }
 *     @include mq($to: tablet) {
 *         color: blue;
 *     }
 *     @include mq(mobile, tablet) {
 *         color: green;
 *     }
 *     @include mq($from: tablet, $and: '(orientation: landscape)') {
 *         color: teal;
 *     }
 *     @include mq(950px) {
 *         color: hotpink;
 *     }
 * }
 */
/**
 * Add a breakpoint
 * Usage: $mqBreakpoints: mqAddBreakpoint(tvscreen, 1920px);
 */
/**
 * Create JSON string of map of breakpoints
 */
/**
 * Create JSON string of single breakpoint
 */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Cardo", serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  color: #F9F4EC;
  text-align: left;
  background-color: #073255;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0; }

p {
  margin-top: 0;
  margin-bottom: 0; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #FAECE6;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0;
  padding-bottom: 0;
  color: #FAECE6;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

/**
 * Grid Settings
 **/
/**
 * Viewports
 **/
/*
@function verticalSpace($color: false, $viewport: mobile) {
	@if(map-has-key($verticalSpaces, $color) and map-has-key($mqBreakpoints, $viewport)) {
		@return pxToRem(map-get(map-get($verticalSpaces, $color), $viewport));
	} @else {
		@return 0;
	}
}*/
/**
 * Inspired from https://github.com/guardian/sass-mq.git
 */
/**
 * // To enable support for browsers that do not support @media queries,
 * (IE <= 8, Firefox <= 3, Opera <= 9) set $mqResponsive to false
 * Create a separate stylesheet served exclusively to these browsers,
 * meaning @media queries will be rasterized, relying on the cascade itself
 */
/**
 * Name your breakpoints in a way that creates a ubiquitous language
 * across team members. It will improve communication between
 * stakeholders, designers, developers, and testers.
 */
/**
 * Define the breakpoint from the $mqBreakpoints list that should
 * be used as the target width when outputting a static stylesheet
 * (i.e. when $mqResponsive is set to 'false').
 */
/**
 * If you want to display the currently active breakpoint in the top
 * right corner of your site during development, add the breakpoints
 * to this list, ordered by width, e.g. (mobile, tablet, desktop).
 */
/**
 * Media Query mixin
 * Usage:
 * .element {
 *     @include mq($from: mobile) {
 *         color: red;
 *     }
 *     @include mq($to: tablet) {
 *         color: blue;
 *     }
 *     @include mq(mobile, tablet) {
 *         color: green;
 *     }
 *     @include mq($from: tablet, $and: '(orientation: landscape)') {
 *         color: teal;
 *     }
 *     @include mq(950px) {
 *         color: hotpink;
 *     }
 * }
 */
/**
 * Add a breakpoint
 * Usage: $mqBreakpoints: mqAddBreakpoint(tvscreen, 1920px);
 */
/**
 * Create JSON string of map of breakpoints
 */
/**
 * Create JSON string of single breakpoint
 */
.app {
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column; }
  @media all and (min-width: 56.25em) {
    .app {
      max-width: 82.5rem; } }
  .app .header {
    display: block; }
    @media all and (min-width: 56.25em) {
      .app .header {
        margin-left: calc((100vw - 100%) / -2);
        width: 100vw; } }
    .app .header__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 20px; }
      @media all and (min-width: 37.5em) {
        .app .header__content {
          padding-top: 32px; } }
      @media all and (min-width: 56.25em) {
        .app .header__content {
          padding-top: 56px; } }
    .app .header .hamburger {
      width: 24px;
      height: 20px;
      position: relative;
      cursor: pointer;
      z-index: 10;
      user-select: none;
      margin-right: calc(((100% - 55px) / 12 * 1));
      margin-left: auto; }
      @media all and (min-width: 37.5em) {
        .app .header .hamburger {
          margin-right: calc(((100% - 88px) / 12 * 0.5)); } }
      @media all and (min-width: 56.25em) {
        .app .header .hamburger {
          margin-right: calc(((100% - 264px) / 12 * 0.5)); } }
      .app .header .hamburger label {
        display: block;
        position: relative;
        cursor: pointer;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0); }
        .app .header .hamburger label span {
          transition: background 0.3s ease; }
        .app .header .hamburger label:hover span {
          background-color: #D7C18A !important; }
        .app .header .hamburger label span:nth-child(1) {
          top: 0;
          position: absolute;
          content: close-quote;
          width: 18px;
          height: 2px;
          background: #fff;
          backface-visibility: hidden; }
        .app .header .hamburger label span:nth-child(2) {
          display: block;
          position: absolute;
          width: 100%;
          height: 2px;
          top: 8px;
          background-color: #fff;
          backface-visibility: hidden; }
        .app .header .hamburger label span:nth-child(3) {
          top: 16px;
          left: 6px;
          position: absolute;
          content: close-quote;
          width: 18px;
          height: 2px;
          background-color: #fff;
          backface-visibility: hidden; }
      .app .header .hamburger input {
        position: absolute;
        opacity: 0; }
      .app .header .hamburger.active label span:nth-child(1) {
        width: 34px;
        transform: rotate(45deg) translate(3px, 9px); }
      .app .header .hamburger.active label span:nth-child(2) {
        transform: scaleY(0);
        transition: transform 0.2s ease; }
      .app .header .hamburger.active label span:nth-child(3) {
        width: 34px;
        transform: rotate(-45deg) translate(1px, -9px);
        left: 0; }
  .app .logo {
    width: 60px;
    height: 47px;
    position: relative;
    margin-left: calc(((100% - 55px) / 12 * 1));
    z-index: 2; }
    @media all and (min-width: 37.5em) {
      .app .logo {
        margin-left: calc(((100% - 88px) / 12 * 0.5)); } }
    @media all and (min-width: 56.25em) {
      .app .logo {
        margin-left: calc(((100% - 264px) / 12 * 0.5)); } }
    .app .logo img {
      width: 100%;
      display: block; }
    .app .logo--hover {
      position: absolute;
      top: 0;
      opacity: 0;
      transition: opacity 0.3s ease; }
    .app .logo:hover .logo--hover {
      opacity: 1; }
  .app .footer {
    background: #1E2124;
    text-transform: uppercase;
    font-family: "Pathway Gothic One", sans-serif;
    position: relative;
    z-index: 4;
    margin-top: auto; }
    @media all and (min-width: 37.5em) {
      .app .footer {
        margin-left: calc((100vw - 100%) / -2); } }
    @media all and (min-width: 56.25em) {
      .app .footer {
        width: 100vw;
        margin-left: calc((100vw - 100%) / -2); } }
    .app .footer--wrapper {
      margin: 0 auto;
      padding: 2rem calc(5px + ((100% - 55px) / 12 * 1)); }
      @media all and (min-width: 56.25em) {
        .app .footer--wrapper {
          max-width: 82.5rem; } }
      @media all and (min-width: 37.5em) {
        .app .footer--wrapper {
          padding: 2rem calc(8px + ((100% - 88px) / 12 * 1)); } }
      @media all and (min-width: 56.25em) {
        .app .footer--wrapper {
          padding: 4.0625rem 1rem;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between; } }
      @media all and (min-width: 68.75em) {
        .app .footer--wrapper {
          flex-wrap: nowrap; } }
    .app .footer__nav {
      display: none;
      margin: 0;
      padding: 0;
      list-style: none; }
      @media all and (min-width: 56.25em) {
        .app .footer__nav {
          display: flex; } }
      .app .footer__nav__item {
        display: flex;
        position: relative;
        align-items: center;
        transition: opacity 0.3s ease; }
        .app .footer__nav__item:hover {
          opacity: 0.5; }
        .app .footer__nav__item:not(:last-child):after {
          display: block;
          content: close-quote;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: #FAECE6;
          margin-right: 14px;
          margin-left: 14px; }
    .app .footer__copyrights {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      @media all and (min-width: 56.25em) {
        .app .footer__copyrights {
          justify-content: flex-end;
          margin-top: 20px; } }
      @media all and (min-width: 68.75em) {
        .app .footer__copyrights {
          justify-content: flex-start;
          margin-top: 0; } }
      .app .footer__copyrights-wrapper {
        display: flex; }
      .app .footer__copyrights__logo {
        display: block;
        margin-right: 14px;
        width: 40px;
        height: 30px; }
      .app .footer__copyrights__text {
        display: flex;
        align-items: center; }
        .app .footer__copyrights__text span:first-child {
          margin-top: 6px; }
      .app .footer__copyrights__link {
        display: flex;
        align-items: center;
        margin-left: 16px; }
        .app .footer__copyrights__link:before {
          content: close-quote;
          display: block;
          margin-right: 16px;
          width: 32px;
          height: 2px;
          background: #F9F4EC; }
        .app .footer__copyrights__link span {
          transition: opacity 0.3s ease; }
        .app .footer__copyrights__link:before {
          height: 1px; }
        .app .footer__copyrights__link:hover > span {
          opacity: 0.5; }

/**
 * Grid Settings
 **/
/**
 * Viewports
 **/
/*
@function verticalSpace($color: false, $viewport: mobile) {
	@if(map-has-key($verticalSpaces, $color) and map-has-key($mqBreakpoints, $viewport)) {
		@return pxToRem(map-get(map-get($verticalSpaces, $color), $viewport));
	} @else {
		@return 0;
	}
}*/
/**
 * Inspired from https://github.com/guardian/sass-mq.git
 */
/**
 * // To enable support for browsers that do not support @media queries,
 * (IE <= 8, Firefox <= 3, Opera <= 9) set $mqResponsive to false
 * Create a separate stylesheet served exclusively to these browsers,
 * meaning @media queries will be rasterized, relying on the cascade itself
 */
/**
 * Name your breakpoints in a way that creates a ubiquitous language
 * across team members. It will improve communication between
 * stakeholders, designers, developers, and testers.
 */
/**
 * Define the breakpoint from the $mqBreakpoints list that should
 * be used as the target width when outputting a static stylesheet
 * (i.e. when $mqResponsive is set to 'false').
 */
/**
 * If you want to display the currently active breakpoint in the top
 * right corner of your site during development, add the breakpoints
 * to this list, ordered by width, e.g. (mobile, tablet, desktop).
 */
/**
 * Media Query mixin
 * Usage:
 * .element {
 *     @include mq($from: mobile) {
 *         color: red;
 *     }
 *     @include mq($to: tablet) {
 *         color: blue;
 *     }
 *     @include mq(mobile, tablet) {
 *         color: green;
 *     }
 *     @include mq($from: tablet, $and: '(orientation: landscape)') {
 *         color: teal;
 *     }
 *     @include mq(950px) {
 *         color: hotpink;
 *     }
 * }
 */
/**
 * Add a breakpoint
 * Usage: $mqBreakpoints: mqAddBreakpoint(tvscreen, 1920px);
 */
/**
 * Create JSON string of map of breakpoints
 */
/**
 * Create JSON string of single breakpoint
 */
.contact-teaser {
  width: 104px;
  height: 106px;
  position: relative;
  display: block; }
  @media all and (min-width: 37.5em) {
    .contact-teaser {
      width: 104px;
      height: 106px; } }
  @media all and (min-width: 56.25em) {
    .contact-teaser {
      width: 144px;
      height: 148px; } }
  .contact-teaser__pen {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 21px;
    height: 31px;
    color: #E66441;
    transform: translate(-50%, -50%); }
  .contact-teaser img {
    width: 100%;
    display: block;
    backface-visibility: hidden; }

/**
 * Grid Settings
 **/
/**
 * Viewports
 **/
/*
@function verticalSpace($color: false, $viewport: mobile) {
	@if(map-has-key($verticalSpaces, $color) and map-has-key($mqBreakpoints, $viewport)) {
		@return pxToRem(map-get(map-get($verticalSpaces, $color), $viewport));
	} @else {
		@return 0;
	}
}*/
/**
 * Inspired from https://github.com/guardian/sass-mq.git
 */
/**
 * // To enable support for browsers that do not support @media queries,
 * (IE <= 8, Firefox <= 3, Opera <= 9) set $mqResponsive to false
 * Create a separate stylesheet served exclusively to these browsers,
 * meaning @media queries will be rasterized, relying on the cascade itself
 */
/**
 * Name your breakpoints in a way that creates a ubiquitous language
 * across team members. It will improve communication between
 * stakeholders, designers, developers, and testers.
 */
/**
 * Define the breakpoint from the $mqBreakpoints list that should
 * be used as the target width when outputting a static stylesheet
 * (i.e. when $mqResponsive is set to 'false').
 */
/**
 * If you want to display the currently active breakpoint in the top
 * right corner of your site during development, add the breakpoints
 * to this list, ordered by width, e.g. (mobile, tablet, desktop).
 */
/**
 * Media Query mixin
 * Usage:
 * .element {
 *     @include mq($from: mobile) {
 *         color: red;
 *     }
 *     @include mq($to: tablet) {
 *         color: blue;
 *     }
 *     @include mq(mobile, tablet) {
 *         color: green;
 *     }
 *     @include mq($from: tablet, $and: '(orientation: landscape)') {
 *         color: teal;
 *     }
 *     @include mq(950px) {
 *         color: hotpink;
 *     }
 * }
 */
/**
 * Add a breakpoint
 * Usage: $mqBreakpoints: mqAddBreakpoint(tvscreen, 1920px);
 */
/**
 * Create JSON string of map of breakpoints
 */
/**
 * Create JSON string of single breakpoint
 */
.animated-link {
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: opacity 0.4s ease; }
  .hidden .animated-link {
    opacity: 0; }
  .animated-link:hover .animated-link__dot--last {
    margin-left: 1px; }
  .inverted .animated-link .animated-link__text {
    margin-right: 0;
    margin-left: 28px; }
  .inverted .animated-link .animated-link__animated-arrow {
    right: initial;
    left: -17px;
    transform: translateY(-50%) scaleX(-1); }
  .white .animated-link .animated-link__text {
    color: #fff; }
  .white .animated-link .animated-link__dot {
    background-color: #fff; }
    .white .animated-link .animated-link__dot:first-child {
      background-color: rgba(255, 255, 255, 0.5); }
  .white .animated-link .animated-link__arrow {
    color: #fff; }
  .orange .animated-link .animated-link__text {
    color: #E66441; }
  .orange .animated-link .animated-link__dot {
    background-color: orange; }
    .orange .animated-link .animated-link__dot:first-child {
      background-color: rgba(230, 100, 65, 0.5); }
  .orange .animated-link .animated-link__arrow {
    color: #E66441; }
  .animated-link__text {
    font-family: "PT Sans Narrow", sans-serif;
    text-transform: uppercase;
    color: #D7C18A;
    margin-right: 28px; }
  .animated-link__animated-arrow {
    position: absolute;
    display: flex;
    align-items: center;
    width: 40px;
    right: -17px;
    top: 50%;
    transform: translateY(-50%); }
    .vertical .animated-link__animated-arrow {
      top: unset;
      transform: rotate(90deg) translateX(-11px);
      transform-origin: left;
      right: -21px; }
  .animated-link__dot {
    display: block;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    background-color: #D7C18A;
    margin-right: 3px;
    transition: margin 0.2s ease-in-out; }
    .animated-link__dot--first {
      background-color: rgba(215, 193, 138, 0.5); }
    .animated-link__dot--last {
      margin-left: -8px; }
  .animated-link__arrow {
    color: #D7C18A;
    width: 7px;
    height: 9px; }

/**
 * Grid Settings
 **/
/**
 * Viewports
 **/
/*
@function verticalSpace($color: false, $viewport: mobile) {
	@if(map-has-key($verticalSpaces, $color) and map-has-key($mqBreakpoints, $viewport)) {
		@return pxToRem(map-get(map-get($verticalSpaces, $color), $viewport));
	} @else {
		@return 0;
	}
}*/
/**
 * Inspired from https://github.com/guardian/sass-mq.git
 */
/**
 * // To enable support for browsers that do not support @media queries,
 * (IE <= 8, Firefox <= 3, Opera <= 9) set $mqResponsive to false
 * Create a separate stylesheet served exclusively to these browsers,
 * meaning @media queries will be rasterized, relying on the cascade itself
 */
/**
 * Name your breakpoints in a way that creates a ubiquitous language
 * across team members. It will improve communication between
 * stakeholders, designers, developers, and testers.
 */
/**
 * Define the breakpoint from the $mqBreakpoints list that should
 * be used as the target width when outputting a static stylesheet
 * (i.e. when $mqResponsive is set to 'false').
 */
/**
 * If you want to display the currently active breakpoint in the top
 * right corner of your site during development, add the breakpoints
 * to this list, ordered by width, e.g. (mobile, tablet, desktop).
 */
/**
 * Media Query mixin
 * Usage:
 * .element {
 *     @include mq($from: mobile) {
 *         color: red;
 *     }
 *     @include mq($to: tablet) {
 *         color: blue;
 *     }
 *     @include mq(mobile, tablet) {
 *         color: green;
 *     }
 *     @include mq($from: tablet, $and: '(orientation: landscape)') {
 *         color: teal;
 *     }
 *     @include mq(950px) {
 *         color: hotpink;
 *     }
 * }
 */
/**
 * Add a breakpoint
 * Usage: $mqBreakpoints: mqAddBreakpoint(tvscreen, 1920px);
 */
/**
 * Create JSON string of map of breakpoints
 */
/**
 * Create JSON string of single breakpoint
 */
.navigation {
  background: #021629;
  z-index: 6;
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  display: none; }
  .navigation--open {
    display: block; }
  .navigation-wrapper {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    font-family: "Pathway Gothic One", sans-serif;
    text-transform: uppercase;
    margin-top: 60px; }
    @media all and (min-width: 37.5em) {
      .navigation-wrapper {
        margin-top: 65px; } }
    @media screen and (min-width: 900px) and (max-height: 800px) {
      .navigation-wrapper {
        margin-top: 0;
        display: flex;
        flex-direction: column; } }
  .navigation__item {
    transition: color 0.3s ease;
    line-height: 1.33333;
    margin-top: 48px;
    font-size: 30px; }
    @media all and (min-width: 37.5em) {
      .navigation__item {
        line-height: 1.42857;
        font-size: 56px; } }
    @media all and (min-width: 56.25em) {
      .navigation__item {
        margin-top: 32px;
        font-size: 50px; } }
    @media screen and (min-width: 900px) and (max-height: 800px) {
      .navigation__item {
        margin-top: 0; } }
    .navigation__item a {
      transition: color 0.4s ease; }
    .navigation__item:hover > a {
      color: #E66441; }
    .navigation__item:first-child {
      margin-top: 0; }
  .navigation .contact-info {
    margin: 0;
    padding: 0;
    list-style: none;
    display: none; }
    @media all and (min-width: 56.25em) {
      .navigation .contact-info {
        display: flex;
        margin-top: 66px; } }
    .navigation .contact-info__item {
      text-align: center; }
      @media all and (min-width: 56.25em) {
        .navigation .contact-info__item {
          width: calc(48px + ((100% - 264px) / 12 * 3));
          margin-right: 24px;
          margin-bottom: 70px; } }
      @media screen and (min-width: 900px) and (max-height: 800px) {
        .navigation .contact-info__item {
          margin-bottom: 0; } }
      .navigation .contact-info__item:last-child {
        margin-right: 0; }
      .navigation .contact-info__item__title {
        font-family: "PT Sans Narrow", sans-serif;
        font-size: 24px;
        text-transform: uppercase;
        color: #D7C18A; }
      .navigation .contact-info__item__value {
        margin-top: 10px;
        font-size: 20px; }
  .navigation .scroll-wrapper {
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; }
    @media screen and (min-width: 900px) and (max-height: 800px) {
      .navigation .scroll-wrapper {
        display: flex;
        flex-direction: column;
        padding: 60px 0; } }
  .navigation .lines {
    top: 0;
    left: 0;
    position: absolute;
    width: 100vw;
    height: 100%;
    display: none; }
    @media all and (min-width: 56.25em) {
      .navigation .lines {
        display: block;
        z-index: -1; } }
    .navigation .lines .line {
      width: 1px;
      height: 100%;
      background-color: rgba(215, 193, 138, 0.19);
      position: absolute; }
      .navigation .lines .line-1 {
        left: calc(24px + ((100% - 264px) / 12 * 1.5)); }
      .navigation .lines .line-2 {
        left: calc(72px + ((100% - 264px) / 12 * 4)); }
      .navigation .lines .line-3 {
        left: calc(168px + ((100% - 264px) / 12 * 8)); }
      .navigation .lines .line-4 {
        left: calc(240px + ((100% - 264px) / 12 * 10.5)); }

/**
 * Grid Settings
 **/
/**
 * Viewports
 **/
/*
@function verticalSpace($color: false, $viewport: mobile) {
	@if(map-has-key($verticalSpaces, $color) and map-has-key($mqBreakpoints, $viewport)) {
		@return pxToRem(map-get(map-get($verticalSpaces, $color), $viewport));
	} @else {
		@return 0;
	}
}*/
/**
 * Inspired from https://github.com/guardian/sass-mq.git
 */
/**
 * // To enable support for browsers that do not support @media queries,
 * (IE <= 8, Firefox <= 3, Opera <= 9) set $mqResponsive to false
 * Create a separate stylesheet served exclusively to these browsers,
 * meaning @media queries will be rasterized, relying on the cascade itself
 */
/**
 * Name your breakpoints in a way that creates a ubiquitous language
 * across team members. It will improve communication between
 * stakeholders, designers, developers, and testers.
 */
/**
 * Define the breakpoint from the $mqBreakpoints list that should
 * be used as the target width when outputting a static stylesheet
 * (i.e. when $mqResponsive is set to 'false').
 */
/**
 * If you want to display the currently active breakpoint in the top
 * right corner of your site during development, add the breakpoints
 * to this list, ordered by width, e.g. (mobile, tablet, desktop).
 */
/**
 * Media Query mixin
 * Usage:
 * .element {
 *     @include mq($from: mobile) {
 *         color: red;
 *     }
 *     @include mq($to: tablet) {
 *         color: blue;
 *     }
 *     @include mq(mobile, tablet) {
 *         color: green;
 *     }
 *     @include mq($from: tablet, $and: '(orientation: landscape)') {
 *         color: teal;
 *     }
 *     @include mq(950px) {
 *         color: hotpink;
 *     }
 * }
 */
/**
 * Add a breakpoint
 * Usage: $mqBreakpoints: mqAddBreakpoint(tvscreen, 1920px);
 */
/**
 * Create JSON string of map of breakpoints
 */
/**
 * Create JSON string of single breakpoint
 */
.homepage {
  position: relative; }
  .homepage .header-background {
    position: absolute;
    width: 100%;
    top: -100px;
    z-index: -1;
    background-color: #05233E; }
    .homepage .header-background:before {
      content: close-quote;
      display: block;
      padding-bottom: 160.2666667%; }
      @media all and (min-width: 37.5em) {
        .homepage .header-background:before {
          padding-bottom: 104.8571429%; } }
    .homepage .header-background__image {
      width: 100%;
      height: 100%;
      background-image: url("./assets/images/lion.jpg");
      background-position: top right;
      background-size: cover;
      position: absolute;
      top: 0; }
      @media all and (min-width: 37.5em) {
        .homepage .header-background__image {
          background-position: top center; } }
    @media all and (min-width: 37.5em) {
      .homepage .header-background {
        display: block; } }
    @media all and (min-width: 56.25em) {
      .homepage .header-background {
        width: 100vw;
        margin-left: calc((100vw - 100%) / -2); } }
  .homepage__hero {
    height: calc(100vh - 103px);
    display: flex;
    flex-direction: column;
    min-height: 600px;
    max-height: 900px; }
  .homepage .title {
    color: #F9F4EC;
    margin-left: calc(5px + ((100% - 55px) / 12 * 2));
    margin-top: 20%; }
    @media all and (min-width: 37.5em) {
      .homepage .title {
        margin-left: calc(16px + ((100% - 88px) / 12 * 2.5));
        margin-top: 10%; } }
    @media all and (min-width: 56.25em) {
      .homepage .title {
        margin-left: calc(48px + ((100% - 264px) / 12 * 2));
        margin-top: 15%; } }
    .homepage .title__1st {
      font-size: 1.25rem;
      font-weight: 400; }
      @media all and (min-width: 37.5em) {
        .homepage .title__1st {
          font-size: 1.375rem; } }
      @media all and (min-width: 56.25em) {
        .homepage .title__1st {
          font-size: 2.5rem; } }
    .homepage .title__2nd {
      font-size: 2.5rem;
      font-family: "Pathway Gothic One", sans-serif;
      font-weight: 400;
      text-transform: uppercase;
      line-height: 1;
      margin-top: 22px; }
      @media all and (min-width: 37.5em) {
        .homepage .title__2nd {
          margin-top: 10px;
          font-size: 5.625rem; } }
      @media all and (min-width: 56.25em) {
        .homepage .title__2nd {
          margin-top: 0;
          font-size: 10.125rem;
          line-height: 1.15432; } }
    .homepage .title__3rd {
      font-size: 1.25rem;
      font-weight: 400;
      display: flex;
      align-items: center;
      margin-top: 10px; }
      @media all and (min-width: 37.5em) {
        .homepage .title__3rd {
          font-size: 1.375rem; } }
      @media all and (min-width: 56.25em) {
        .homepage .title__3rd {
          margin-top: 0;
          font-size: 2.5rem; } }
      .homepage .title__3rd:before {
        content: close-quote;
        display: block;
        margin-right: 16px;
        width: 48px;
        height: 2px;
        background: #F9F4EC; }
  .homepage .scroll-for-more {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "Pathway Gothic One", sans-serif;
    margin-top: auto;
    margin-left: calc(5px + ((100% - 55px) / 12 * 2));
    height: 106px; }
    @media all and (min-width: 37.5em) {
      .homepage .scroll-for-more {
        margin-left: calc(((100% - 88px) / 12 * 1));
        margin-right: calc(((100% - 88px) / 12 * 1)); } }
    @media all and (min-width: 56.25em) {
      .homepage .scroll-for-more {
        margin-left: calc((100vw - 100%) / -2);
        padding: 0 60px;
        height: 148px;
        width: 100vw; } }
    .homepage .scroll-for-more .vertical {
      flex: 1 0 auto; }
    @media all and (max-width: 37.49em) {
      .homepage .scroll-for-more .contact-teaser {
        display: none; } }
  .homepage .expertise {
    margin-top: 100px;
    padding: 0 calc(5px + ((100% - 55px) / 12 * 2)); }
    @media all and (min-width: 37.5em) {
      .homepage .expertise {
        margin-top: 140px;
        padding: 0 calc(48px + ((100% - 264px) / 12 * 2.5)); } }
    @media all and (min-width: 56.25em) {
      .homepage .expertise {
        margin-top: 200px;
        padding: 0 calc(24px + ((100% - 264px) / 12 * 2)); } }
    .homepage .expertise__header {
      font-size: 22px;
      font-weight: 400; }
    .homepage .expertise__box {
      position: relative;
      margin-top: 72px; }
      .homepage .expertise__box:first-child {
        margin-top: 16px; }
      @media all and (min-width: 37.5em) {
        .homepage .expertise__box {
          width: calc(40px + ((100% - 48px) / 7 * 6));
          margin-top: 80px; }
          .homepage .expertise__box:first-child {
            margin-top: 80px; } }
      @media all and (min-width: 56.25em) {
        .homepage .expertise__box {
          width: calc(72px + ((100% - 216px) / 10 * 4));
          height: 370px;
          display: flex;
          flex-direction: column;
          margin-top: 0; }
          .homepage .expertise__box:first-child {
            margin-top: 0; } }
      .homepage .expertise__box__title {
        font-family: "Pathway Gothic One", sans-serif;
        font-weight: 400;
        text-transform: uppercase;
        line-height: 1;
        font-size: 40px; }
        @media all and (min-width: 37.5em) {
          .homepage .expertise__box__title {
            font-size: 60px; } }
        @media all and (min-width: 56.25em) {
          .homepage .expertise__box__title {
            font-size: 60px; } }
      .homepage .expertise__box__text {
        margin-top: 16px; }
        @media all and (min-width: 37.5em) {
          .homepage .expertise__box__text {
            margin-top: 32px;
            font-size: 20px;
            line-height: 1.6; } }
        .homepage .expertise__box__text .intro-line {
          display: inline-block;
          vertical-align: middle; }
          .homepage .expertise__box__text .intro-line:before {
            content: close-quote;
            display: block;
            margin-right: 12px;
            width: 32px;
            height: 2px;
            background: #D7C18A; }
        @media all and (max-width: 37.49em) {
          .homepage .expertise__box__text span:last-child {
            margin-top: 24px; } }
      .homepage .expertise__box__link {
        display: block;
        margin-top: 24px;
        align-self: flex-end; }
        @media all and (min-width: 37.5em) {
          .homepage .expertise__box__link {
            margin-top: 56px; } }
        @media all and (min-width: 56.25em) {
          .homepage .expertise__box__link {
            position: absolute;
            bottom: 0; } }
    @media all and (min-width: 56.25em) {
      .homepage .expertise__box-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 100px;
        width: calc(216px + (100% - 216px)); } }
  .homepage .hires-spacer {
    position: relative; }
    @media all and (min-width: 101.25em) {
      .homepage .hires-spacer {
        width: 100vw;
        margin-left: calc((100vw - 100%) / -2); } }
    .homepage .hires-spacer:after {
      display: block;
      width: 100%;
      content: close-quote;
      padding-bottom: 1%; }
      @media all and (min-width: 101.25em) {
        .homepage .hires-spacer:after {
          padding-bottom: 25%; } }
      @media all and (min-width: 112.5em) {
        .homepage .hires-spacer:after {
          padding-bottom: 40%; } }
  .homepage .about-us {
    text-align: center;
    position: relative;
    padding-top: 300px;
    padding-bottom: 90px; }
    @media all and (min-width: 37.5em) {
      .homepage .about-us {
        padding-top: 344px;
        padding-bottom: 210px; } }
    @media all and (min-width: 56.25em) {
      .homepage .about-us {
        padding-top: 470px;
        padding-bottom: 220px; } }
    .homepage .about-us__background {
      z-index: -3;
      position: absolute;
      background-image: url("./assets/images/mountains.jpg");
      background-position: top center;
      background-size: cover;
      width: 100vw;
      height: 95%;
      bottom: 0; }
      @media all and (min-width: 56.25em) {
        .homepage .about-us__background {
          margin-left: calc((100vw - 100%) / -2); } }
    .homepage .about-us__title {
      position: relative;
      font-size: 22px;
      font-weight: 400; }
    .homepage .about-us__cta {
      position: relative;
      color: #D7C18A;
      text-transform: uppercase;
      font-family: "Pathway Gothic One", sans-serif;
      margin-top: 32px;
      font-size: 40px;
      width: calc(35px + ((100% - 55px) / 12 * 8));
      margin-left: calc(5px + ((100% - 55px) / 12 * 2));
      line-height: 1; }
      @media all and (min-width: 37.5em) {
        .homepage .about-us__cta {
          font-size: 60px;
          margin-top: 72px;
          margin-left: calc(16px + ((100% - 88px) / 12 * 3));
          line-height: 1.16667;
          width: calc(40px + ((100% - 88px) / 12 * 6)); } }
      @media all and (min-width: 56.25em) {
        .homepage .about-us__cta {
          font-size: 80px;
          margin-top: 50px;
          margin-left: calc(24px + ((100% - 264px) / 12 * 1));
          width: calc(216px + ((100% - 264px) / 12 * 10)); } }
    .homepage .about-us__link {
      position: relative;
      font-family: "Pathway Gothic One", sans-serif;
      text-transform: uppercase;
      display: inline-block;
      margin-top: 72px; }
      @media all and (min-width: 37.5em) {
        .homepage .about-us__link {
          margin-top: 124px; } }

/**
 * Grid Settings
 **/
/**
 * Viewports
 **/
/*
@function verticalSpace($color: false, $viewport: mobile) {
	@if(map-has-key($verticalSpaces, $color) and map-has-key($mqBreakpoints, $viewport)) {
		@return pxToRem(map-get(map-get($verticalSpaces, $color), $viewport));
	} @else {
		@return 0;
	}
}*/
/**
 * Inspired from https://github.com/guardian/sass-mq.git
 */
/**
 * // To enable support for browsers that do not support @media queries,
 * (IE <= 8, Firefox <= 3, Opera <= 9) set $mqResponsive to false
 * Create a separate stylesheet served exclusively to these browsers,
 * meaning @media queries will be rasterized, relying on the cascade itself
 */
/**
 * Name your breakpoints in a way that creates a ubiquitous language
 * across team members. It will improve communication between
 * stakeholders, designers, developers, and testers.
 */
/**
 * Define the breakpoint from the $mqBreakpoints list that should
 * be used as the target width when outputting a static stylesheet
 * (i.e. when $mqResponsive is set to 'false').
 */
/**
 * If you want to display the currently active breakpoint in the top
 * right corner of your site during development, add the breakpoints
 * to this list, ordered by width, e.g. (mobile, tablet, desktop).
 */
/**
 * Media Query mixin
 * Usage:
 * .element {
 *     @include mq($from: mobile) {
 *         color: red;
 *     }
 *     @include mq($to: tablet) {
 *         color: blue;
 *     }
 *     @include mq(mobile, tablet) {
 *         color: green;
 *     }
 *     @include mq($from: tablet, $and: '(orientation: landscape)') {
 *         color: teal;
 *     }
 *     @include mq(950px) {
 *         color: hotpink;
 *     }
 * }
 */
/**
 * Add a breakpoint
 * Usage: $mqBreakpoints: mqAddBreakpoint(tvscreen, 1920px);
 */
/**
 * Create JSON string of map of breakpoints
 */
/**
 * Create JSON string of single breakpoint
 */
.about-wrapper .contact-teaser-wrapper {
  display: none; }
  @media all and (min-width: 37.5em) {
    .about-wrapper .contact-teaser-wrapper {
      right: calc(32px - ((100vw - 100%) / 2));
      top: 0;
      position: absolute;
      min-height: 600px;
      max-height: 900px;
      height: 100vh;
      width: 148px;
      display: block; } }
  @media all and (min-width: 56.25em) {
    .about-wrapper .contact-teaser-wrapper {
      right: calc(60px - ((100vw - 100%) / 2)); } }

.about-wrapper .contact-teaser {
  bottom: 0;
  position: absolute; }

.about {
  position: relative;
  padding: 60px 0 100px;
  margin: 0 calc(5px + ((100% - 55px) / 12 * 1.5));
  flex: 1 0 auto; }
  @media all and (min-width: 37.5em) {
    .about {
      padding: 108px 0 180px;
      margin: 0 calc(16px + ((100% - 88px) / 12 * 2)); } }
  @media all and (min-width: 56.25em) {
    .about {
      padding: 180px 0 220px;
      margin: 0 calc(24px + ((100% - 264px) / 12 * 1)); } }
  @media all and (min-width: 56.25em) {
    .about .background-lines {
      padding: 0; } }
  .about__section-name {
    font-size: 20px; }
    @media all and (min-width: 56.25em) {
      .about__section-name {
        font-size: 30px; } }
  .about__section-header {
    font-family: "Pathway Gothic One", sans-serif;
    font-weight: 400;
    font-size: 40px;
    text-transform: uppercase;
    color: #D7C18A;
    line-height: 1;
    margin-top: 32px;
    width: calc(30px + ((100% - 35px) / 8 * 7)); }
    @media all and (min-width: 37.5em) {
      .about__section-header {
        font-size: 60px; } }
    @media all and (min-width: 56.25em) {
      .about__section-header {
        font-size: 80px; } }
    @media all and (min-width: 37.5em) {
      .about__section-header {
        margin-top: 60px;
        width: calc(40px + ((100% - 56px) / 8 * 6)); } }
    @media all and (min-width: 56.25em) {
      .about__section-header {
        margin-top: 65px;
        width: calc(168px + ((100% - 216px) / 10 * 8)); } }
  .about__content {
    font-weight: 400;
    font-size: 1rem;
    color: #F9F4EC;
    line-height: 1.5; }
    @media all and (min-width: 37.5em) {
      .about__content {
        line-height: 1.6;
        font-size: 20px; } }
    .about__content:nth-child(even) {
      margin-top: 24px; }
    @media all and (min-width: 37.5em) {
      .about__content {
        width: calc(48px + ((100% - 56px) / 8 * 7)); }
        .about__content:nth-child(even) {
          margin-top: 64px; } }
    @media all and (min-width: 56.25em) {
      .about__content {
        width: calc(72px + ((100% - 216px) / 10 * 4));
        margin-left: calc(((100% - 216px) / 10 * 1)); }
        .about__content:nth-child(even) {
          margin-top: 200px; } }
  .about__content-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 76px; }
    @media all and (min-width: 37.5em) {
      .about__content-wrapper {
        margin-top: 100px; } }
    @media all and (min-width: 56.25em) {
      .about__content-wrapper {
        margin-top: 80px;
        flex-direction: row; } }
  .about__background {
    z-index: -3;
    position: absolute;
    background-image: url("./assets/images/mountains.jpg");
    background-position: top left;
    background-size: cover;
    width: 100vw;
    height: 50%;
    bottom: 0;
    margin-left: calc((100vw - 100%) / -2); }
  .about .animated-link {
    margin-top: 56px; }
    @media all and (min-width: 37.5em) {
      .about .animated-link {
        display: none; } }

/**
 * Grid Settings
 **/
/**
 * Viewports
 **/
/*
@function verticalSpace($color: false, $viewport: mobile) {
	@if(map-has-key($verticalSpaces, $color) and map-has-key($mqBreakpoints, $viewport)) {
		@return pxToRem(map-get(map-get($verticalSpaces, $color), $viewport));
	} @else {
		@return 0;
	}
}*/
/**
 * Inspired from https://github.com/guardian/sass-mq.git
 */
/**
 * // To enable support for browsers that do not support @media queries,
 * (IE <= 8, Firefox <= 3, Opera <= 9) set $mqResponsive to false
 * Create a separate stylesheet served exclusively to these browsers,
 * meaning @media queries will be rasterized, relying on the cascade itself
 */
/**
 * Name your breakpoints in a way that creates a ubiquitous language
 * across team members. It will improve communication between
 * stakeholders, designers, developers, and testers.
 */
/**
 * Define the breakpoint from the $mqBreakpoints list that should
 * be used as the target width when outputting a static stylesheet
 * (i.e. when $mqResponsive is set to 'false').
 */
/**
 * If you want to display the currently active breakpoint in the top
 * right corner of your site during development, add the breakpoints
 * to this list, ordered by width, e.g. (mobile, tablet, desktop).
 */
/**
 * Media Query mixin
 * Usage:
 * .element {
 *     @include mq($from: mobile) {
 *         color: red;
 *     }
 *     @include mq($to: tablet) {
 *         color: blue;
 *     }
 *     @include mq(mobile, tablet) {
 *         color: green;
 *     }
 *     @include mq($from: tablet, $and: '(orientation: landscape)') {
 *         color: teal;
 *     }
 *     @include mq(950px) {
 *         color: hotpink;
 *     }
 * }
 */
/**
 * Add a breakpoint
 * Usage: $mqBreakpoints: mqAddBreakpoint(tvscreen, 1920px);
 */
/**
 * Create JSON string of map of breakpoints
 */
/**
 * Create JSON string of single breakpoint
 */
.contact {
  padding: 60px calc(5px + ((100% - 55px) / 12 * 1)) 0;
  flex: 1 0 auto;
  position: relative; }
  @media all and (min-width: 37.5em) {
    .contact {
      padding: 80px calc(8px + ((100% - 88px) / 12 * 1)) 0; } }
  @media all and (min-width: 56.25em) {
    .contact {
      padding: 80px calc(24px + ((100% - 264px) / 12 * 1)) 0; } }
  .contact .background-lines {
    z-index: 1; }
  .contact__box {
    background: #021629;
    padding: 20px calc(10px + ((100% - 55px) / 12 * 1)) 70px;
    width: calc(40px + ((100% - 45px) / 10 * 9));
    position: relative;
    z-index: 2; }
    @media all and (min-width: 37.5em) {
      .contact__box {
        padding: 30px 30px 150px;
        width: calc(48px + ((100% - 72px) / 10 * 7)); } }
    @media all and (min-width: 56.25em) {
      .contact__box {
        padding: 48px calc(((100% - 216px) / 10 * 0.5)) 230px;
        width: calc(144px + ((100% - 216px) / 10 * 7)); } }
    .contact__box__header {
      font-family: "Pathway Gothic One", sans-serif;
      font-weight: 400;
      font-size: 40px;
      text-transform: uppercase;
      color: #F9F4EC;
      line-height: 1;
      font-family: "Pathway Gothic One", sans-serif; }
      @media all and (min-width: 37.5em) {
        .contact__box__header {
          font-size: 60px; } }
      @media all and (min-width: 56.25em) {
        .contact__box__header {
          font-size: 80px; } }
    .contact__box__title {
      text-transform: uppercase;
      font-family: "PT Sans Narrow", sans-serif;
      margin-top: 32px; }
      .contact__box__title:first-of-type {
        margin-top: 20px; }
    @media all and (min-width: 37.5em) {
      .contact__box__value {
        width: calc(24px + ((100% - 48px) / 7 * 4)); } }
    @media all and (min-width: 56.25em) {
      .contact__box__value {
        width: calc(48px + ((100% - 144px) / 7 * 3)); } }
    .contact__box .animated-link {
      margin-top: 32px; }
  .contact__map {
    width: calc(45px + (100% - 45px));
    background: #1E2124;
    position: absolute;
    z-index: 3;
    width: 112%;
    bottom: 0;
    transform: translateY(85%); }
    @media all and (min-width: 37.5em) {
      .contact__map {
        position: absolute;
        width: 120%;
        bottom: 0;
        transform: translate(26%, 60%); } }
    @media all and (min-width: 56.25em) {
      .contact__map {
        position: absolute;
        width: calc(144px + (100% - 144px));
        transform: none;
        right: calc(-1 * (72px + ((100% - 144px) / 7 * 4)));
        bottom: 50px; } }
    .contact__map:after {
      display: block;
      content: close-quote;
      padding-bottom: 100%; }
      @media all and (min-width: 37.5em) {
        .contact__map:after {
          padding-bottom: 74.4878957%; } }
      @media all and (min-width: 56.25em) {
        .contact__map:after {
          padding-bottom: 63.7933426%; } }
    .contact__map__gmap {
      position: absolute;
      width: 100%;
      height: 100%; }
      .contact__map__gmap iframe {
        border: 0; }
    .contact__map .map-desktop-bg {
      display: none; }
      @media all and (min-width: 56.25em) {
        .contact__map .map-desktop-bg {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 24px;
          top: 24px;
          display: block;
          background: #021629; } }
  .contact__directions {
    margin: 0 calc(10px + ((100% - 55px) / 12 * 1)) 40px;
    width: calc(30px + ((100% - 45px) / 10 * 7));
    font-weight: 400;
    font-size: 1rem;
    color: #F9F4EC;
    line-height: 1.5; }
    @media all and (min-width: 37.5em) {
      .contact__directions {
        line-height: 1.6;
        font-size: 20px; } }
    @media all and (min-width: 37.5em) {
      .contact__directions {
        width: calc(48px + ((100% - 72px) / 10 * 7));
        margin: 0 calc(8px + ((100% - 72px) / 10 * 1)) 66px; } }
    @media all and (min-width: 56.25em) {
      .contact__directions {
        margin: 155px 0 0 calc(120px + ((100% - 216px) / 10 * 5.5));
        width: calc(72px + ((100% - 216px) / 10 * 4)); } }
    .contact__directions:before {
      display: block;
      content: close-quote;
      width: 100%;
      padding-bottom: 145%; }
      @media all and (min-width: 37.5em) {
        .contact__directions:before {
          padding-bottom: 74.4878957%; } }
      @media all and (min-width: 56.25em) {
        .contact__directions:before {
          display: none; } }
  .contact__image {
    overflow: hidden;
    width: 100vw;
    height: 300px;
    position: relative;
    margin-left: calc((100vw - 100%) / -2); }
    @media all and (min-width: 37.5em) {
      .contact__image {
        height: 500px; } }
    @media all and (min-width: 56.25em) {
      .contact__image {
        overflow: visible;
        margin-top: -160px;
        z-index: -1;
        margin-left: calc(calc(24px + ((100% - 264px) / 12 * 2)) * -2); } }
    @media all and (min-width: 82.5em) {
      .contact__image {
        margin-left: calc((100vw - 100%) / -2);
        bottom: 0; } }
    .contact__image .lines {
      height: 100%;
      position: absolute;
      bottom: 0;
      left: -100px; }
      @media all and (min-width: 37.5em) {
        .contact__image .lines {
          left: -150px; } }
      @media all and (min-width: 56.25em) {
        .contact__image .lines {
          left: 0; } }

/**
 * Grid Settings
 **/
/**
 * Viewports
 **/
/*
@function verticalSpace($color: false, $viewport: mobile) {
	@if(map-has-key($verticalSpaces, $color) and map-has-key($mqBreakpoints, $viewport)) {
		@return pxToRem(map-get(map-get($verticalSpaces, $color), $viewport));
	} @else {
		@return 0;
	}
}*/
/**
 * Inspired from https://github.com/guardian/sass-mq.git
 */
/**
 * // To enable support for browsers that do not support @media queries,
 * (IE <= 8, Firefox <= 3, Opera <= 9) set $mqResponsive to false
 * Create a separate stylesheet served exclusively to these browsers,
 * meaning @media queries will be rasterized, relying on the cascade itself
 */
/**
 * Name your breakpoints in a way that creates a ubiquitous language
 * across team members. It will improve communication between
 * stakeholders, designers, developers, and testers.
 */
/**
 * Define the breakpoint from the $mqBreakpoints list that should
 * be used as the target width when outputting a static stylesheet
 * (i.e. when $mqResponsive is set to 'false').
 */
/**
 * If you want to display the currently active breakpoint in the top
 * right corner of your site during development, add the breakpoints
 * to this list, ordered by width, e.g. (mobile, tablet, desktop).
 */
/**
 * Media Query mixin
 * Usage:
 * .element {
 *     @include mq($from: mobile) {
 *         color: red;
 *     }
 *     @include mq($to: tablet) {
 *         color: blue;
 *     }
 *     @include mq(mobile, tablet) {
 *         color: green;
 *     }
 *     @include mq($from: tablet, $and: '(orientation: landscape)') {
 *         color: teal;
 *     }
 *     @include mq(950px) {
 *         color: hotpink;
 *     }
 * }
 */
/**
 * Add a breakpoint
 * Usage: $mqBreakpoints: mqAddBreakpoint(tvscreen, 1920px);
 */
/**
 * Create JSON string of map of breakpoints
 */
/**
 * Create JSON string of single breakpoint
 */
.privacy-policy {
  padding: 60px calc(5px + ((100% - 55px) / 12 * 2)); }
  @media all and (min-width: 37.5em) {
    .privacy-policy {
      padding: 80px calc(8px + ((100% - 88px) / 12 * 2)); } }
  @media all and (min-width: 56.25em) {
    .privacy-policy {
      padding: 124px calc(((100% - 264px) / 12 * 0.5)) 88px; } }
  .privacy-policy__title {
    font-family: "Pathway Gothic One", sans-serif;
    font-weight: 400;
    font-size: 40px;
    text-transform: uppercase;
    color: #D7C18A;
    line-height: 1; }
    @media all and (min-width: 37.5em) {
      .privacy-policy__title {
        font-size: 60px; } }
    @media all and (min-width: 56.25em) {
      .privacy-policy__title {
        font-size: 80px; } }
  .privacy-policy__content {
    margin-top: 64px; }
    .privacy-policy__content p {
      font-weight: 400;
      font-size: 1rem;
      color: #F9F4EC;
      line-height: 1.5;
      margin-top: 24px; }
      @media all and (min-width: 37.5em) {
        .privacy-policy__content p {
          line-height: 1.6;
          font-size: 20px; } }
      @media all and (min-width: 37.5em) {
        .privacy-policy__content p {
          margin-top: 24px; } }
      @media all and (min-width: 56.25em) {
        .privacy-policy__content p {
          margin-top: 32px; } }
    .privacy-policy__content ul {
      font-weight: 400;
      font-size: 1rem;
      color: #F9F4EC;
      line-height: 1.5;
      margin-top: 24px; }
      @media all and (min-width: 37.5em) {
        .privacy-policy__content ul {
          line-height: 1.6;
          font-size: 20px; } }
    .privacy-policy__content h2 {
      margin-top: 40px; }
      @media all and (min-width: 37.5em) {
        .privacy-policy__content h2 {
          margin-top: 60px; } }
      @media all and (min-width: 56.25em) {
        .privacy-policy__content h2 {
          margin-top: 74px;
          font-size: 30px; } }

/**
 * Grid Settings
 **/
/**
 * Viewports
 **/
/*
@function verticalSpace($color: false, $viewport: mobile) {
	@if(map-has-key($verticalSpaces, $color) and map-has-key($mqBreakpoints, $viewport)) {
		@return pxToRem(map-get(map-get($verticalSpaces, $color), $viewport));
	} @else {
		@return 0;
	}
}*/
/**
 * Inspired from https://github.com/guardian/sass-mq.git
 */
/**
 * // To enable support for browsers that do not support @media queries,
 * (IE <= 8, Firefox <= 3, Opera <= 9) set $mqResponsive to false
 * Create a separate stylesheet served exclusively to these browsers,
 * meaning @media queries will be rasterized, relying on the cascade itself
 */
/**
 * Name your breakpoints in a way that creates a ubiquitous language
 * across team members. It will improve communication between
 * stakeholders, designers, developers, and testers.
 */
/**
 * Define the breakpoint from the $mqBreakpoints list that should
 * be used as the target width when outputting a static stylesheet
 * (i.e. when $mqResponsive is set to 'false').
 */
/**
 * If you want to display the currently active breakpoint in the top
 * right corner of your site during development, add the breakpoints
 * to this list, ordered by width, e.g. (mobile, tablet, desktop).
 */
/**
 * Media Query mixin
 * Usage:
 * .element {
 *     @include mq($from: mobile) {
 *         color: red;
 *     }
 *     @include mq($to: tablet) {
 *         color: blue;
 *     }
 *     @include mq(mobile, tablet) {
 *         color: green;
 *     }
 *     @include mq($from: tablet, $and: '(orientation: landscape)') {
 *         color: teal;
 *     }
 *     @include mq(950px) {
 *         color: hotpink;
 *     }
 * }
 */
/**
 * Add a breakpoint
 * Usage: $mqBreakpoints: mqAddBreakpoint(tvscreen, 1920px);
 */
/**
 * Create JSON string of map of breakpoints
 */
/**
 * Create JSON string of single breakpoint
 */
.work {
  overflow-x: hidden;
  padding: 60px calc(((100% - 55px) / 12 * 1)); }
  @media all and (min-width: 37.5em) {
    .work {
      padding: 84px calc(16px + ((100% - 88px) / 12 * 2)); } }
  @media all and (min-width: 56.25em) {
    .work {
      padding: 124px calc(24px + ((100% - 264px) / 12 * 1.5)); } }
  .work.dark:before {
    background-color: #021629;
    content: close-quote;
    display: block;
    position: absolute;
    top: 0;
    left: calc((100vw - 100%) / -2);
    width: 100vw;
    height: 100%;
    z-index: -3; }
  .work.dark .top-content:before {
    background-color: #021629; }
  .work .top-content {
    position: relative; }
  .work .top-content:before {
    display: block;
    content: close-quote;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 140px;
    background: #073255; }
  .work__title {
    font-family: "Pathway Gothic One", sans-serif;
    font-weight: 400;
    font-size: 40px;
    text-transform: uppercase;
    color: #D7C18A;
    line-height: 1;
    position: relative;
    z-index: 1; }
    @media all and (min-width: 37.5em) {
      .work__title {
        font-size: 60px; } }
    @media all and (min-width: 56.25em) {
      .work__title {
        font-size: 80px; } }
  .work__intro-wrapper {
    font-weight: 400;
    font-size: 1rem;
    color: #F9F4EC;
    line-height: 1.5;
    display: flex;
    flex-direction: column;
    margin-top: 32px; }
    @media all and (min-width: 37.5em) {
      .work__intro-wrapper {
        line-height: 1.6;
        font-size: 20px; } }
    @media all and (min-width: 37.5em) {
      .work__intro-wrapper {
        margin-top: 32px; } }
    @media all and (min-width: 56.25em) {
      .work__intro-wrapper {
        flex-direction: row;
        margin-top: 40px; } }
  .work__intro__header {
    font-family: "Pathway Gothic One", sans-serif;
    font-weight: 400;
    font-size: 40px;
    text-transform: uppercase;
    color: #F9F4EC;
    line-height: 1;
    margin-top: 40px;
    margin-bottom: 16px; }
    @media all and (min-width: 37.5em) {
      .work__intro__header {
        font-size: 48px; } }
    @media all and (min-width: 56.25em) {
      .work__intro__header {
        font-size: 48px; } }
    @media all and (min-width: 37.5em) {
      .work__intro__header {
        margin-top: 120px; } }
    @media all and (min-width: 56.25em) {
      .work__intro__header {
        margin-bottom: 16px;
        margin-top: 32px; } }
  .work__intro__image {
    position: relative;
    display: none; }
    @media all and (min-width: 56.25em) {
      .work__intro__image {
        display: block;
        margin-top: -80px; } }
    .work__intro__image:after {
      content: close-quote;
      display: block; }
      @media all and (min-width: 56.25em) {
        .work__intro__image:after {
          height: 590px;
          padding-left: 140.4721754%; } }
    .work__intro__image img {
      display: block;
      height: 100%;
      position: absolute; }
      @media all and (min-width: 56.25em) {
        .work__intro__image img {
          left: 50%;
          transform: translateX(-50%); } }
  .work__intro-left-col {
    position: relative;
    z-index: 1; }
    @media all and (min-width: 56.25em) {
      .work__intro-left-col {
        width: calc(72px + ((100% - 192px) / 9 * 4));
        flex: 0 0 auto;
        margin-right: calc(24px + ((100% - 192px) / 9 * 1));
        padding-left: 24px; } }
    .work__intro-left-col .lion {
      top: 0;
      z-index: -1;
      position: absolute;
      display: block;
      height: 593px;
      left: 50%;
      transform: translateX(-50%); }
      @media all and (min-width: 56.25em) {
        .work__intro-left-col .lion {
          display: none; } }
  .work__intro-right-col {
    position: relative; }
    @media all and (min-width: 56.25em) {
      .work__intro-right-col {
        flex: 0 0 auto;
        width: calc(72px + ((100% - 192px) / 9 * 4)); } }
  @media all and (min-width: 56.25em) {
    .work__experience-wrapper {
      margin-top: 56px; }
      .work__experience-wrapper:after {
        content: close-quote;
        width: 0;
        height: 0;
        clear: both; } }
  .work__experience__header {
    font-family: "Pathway Gothic One", sans-serif;
    font-weight: 400;
    font-size: 40px;
    text-transform: uppercase;
    color: #F9F4EC;
    line-height: 1;
    margin-top: 60px; }
    @media all and (min-width: 37.5em) {
      .work__experience__header {
        font-size: 48px; } }
    @media all and (min-width: 56.25em) {
      .work__experience__header {
        font-size: 48px; } }
    @media all and (min-width: 56.25em) {
      .work__experience__header {
        margin-top: 100px;
        margin-left: calc(((100% - 192px) / 9 * 1)); } }
  .work__experience__box {
    background: #021629;
    padding: 32px;
    font-size: 16px;
    line-height: 1.6;
    margin-top: 24px;
    width: 110%;
    margin-left: -5%; }
    .work__experience__box--extra {
      background-color: transparent !important;
      padding-top: 0; }
      .work__experience__box--extra .animated-link {
        margin-top: 32px; }
    .dark .work__experience__box {
      background-color: #05233E; }
    @media all and (min-width: 37.5em) {
      .work__experience__box {
        width: 110%;
        margin-left: -5%;
        margin-top: 24px;
        font-size: 20px; }
        .work__experience__box:first-child {
          margin-top: 40px; } }
    @media all and (min-width: 56.25em) {
      .work__experience__box {
        width: calc(72px + ((100% - 192px) / 9 * 3.5));
        margin-top: 64px; }
        .work__experience__box:first-child {
          margin-top: 0; }
        .work__experience__box:nth-child(even) {
          float: right;
          margin-right: calc(((100% - 192px) / 9 * 1)); }
        .work__experience__box:nth-child(odd) {
          float: left;
          margin-left: calc(((100% - 192px) / 9 * 1)); } }

/**
 * Grid Settings
 **/
/**
 * Viewports
 **/
/*
@function verticalSpace($color: false, $viewport: mobile) {
	@if(map-has-key($verticalSpaces, $color) and map-has-key($mqBreakpoints, $viewport)) {
		@return pxToRem(map-get(map-get($verticalSpaces, $color), $viewport));
	} @else {
		@return 0;
	}
}*/
/**
 * Inspired from https://github.com/guardian/sass-mq.git
 */
/**
 * // To enable support for browsers that do not support @media queries,
 * (IE <= 8, Firefox <= 3, Opera <= 9) set $mqResponsive to false
 * Create a separate stylesheet served exclusively to these browsers,
 * meaning @media queries will be rasterized, relying on the cascade itself
 */
/**
 * Name your breakpoints in a way that creates a ubiquitous language
 * across team members. It will improve communication between
 * stakeholders, designers, developers, and testers.
 */
/**
 * Define the breakpoint from the $mqBreakpoints list that should
 * be used as the target width when outputting a static stylesheet
 * (i.e. when $mqResponsive is set to 'false').
 */
/**
 * If you want to display the currently active breakpoint in the top
 * right corner of your site during development, add the breakpoints
 * to this list, ordered by width, e.g. (mobile, tablet, desktop).
 */
/**
 * Media Query mixin
 * Usage:
 * .element {
 *     @include mq($from: mobile) {
 *         color: red;
 *     }
 *     @include mq($to: tablet) {
 *         color: blue;
 *     }
 *     @include mq(mobile, tablet) {
 *         color: green;
 *     }
 *     @include mq($from: tablet, $and: '(orientation: landscape)') {
 *         color: teal;
 *     }
 *     @include mq(950px) {
 *         color: hotpink;
 *     }
 * }
 */
/**
 * Add a breakpoint
 * Usage: $mqBreakpoints: mqAddBreakpoint(tvscreen, 1920px);
 */
/**
 * Create JSON string of map of breakpoints
 */
/**
 * Create JSON string of single breakpoint
 */
.people__dark-background {
  position: relative; }
  .people__dark-background:after {
    content: close-quote;
    position: absolute;
    display: block;
    width: 100vw;
    height: calc(100% + 300px);
    top: -300px;
    left: calc((100vw - 100%) / -2);
    background-color: #021629;
    z-index: -3; }

.people__header {
  margin-top: 60px;
  padding: 0 calc(((100% - 55px) / 12 * 1)); }
  @media all and (min-width: 37.5em) {
    .people__header {
      margin-top: 84px;
      padding-left: calc(16px + ((100% - 88px) / 12 * 2));
      padding-right: calc(16px + ((100% - 88px) / 12 * 2)); } }
  @media all and (min-width: 56.25em) {
    .people__header {
      margin-top: 152px;
      display: flex;
      padding-left: calc(24px + ((100% - 264px) / 12 * 1));
      padding-right: calc(24px + ((100% - 264px) / 12 * 1)); } }
  .people__header h1 {
    font-family: "Pathway Gothic One", sans-serif;
    font-weight: 400;
    font-size: 40px;
    text-transform: uppercase;
    color: #fff;
    line-height: 1; }
    @media all and (min-width: 37.5em) {
      .people__header h1 {
        font-size: 60px; } }
    @media all and (min-width: 56.25em) {
      .people__header h1 {
        font-size: 80px; } }
    @media all and (min-width: 56.25em) {
      .people__header h1 {
        width: calc(120px + ((100% - 216px) / 10 * 5));
        margin-right: calc(((100% - 216px) / 10 * 0.5)); } }
  .people__header p {
    font-weight: 400;
    font-size: 1rem;
    color: #F9F4EC;
    line-height: 1.5;
    margin-top: 40px; }
    @media all and (min-width: 37.5em) {
      .people__header p {
        line-height: 1.6;
        font-size: 20px; } }
    @media all and (min-width: 37.5em) {
      .people__header p {
        width: calc(40px + ((100% - 56px) / 8 * 6));
        margin-top: 50px; } }
    @media all and (min-width: 56.25em) {
      .people__header p {
        width: calc(72px + ((100% - 216px) / 10 * 4));
        margin-top: 0; } }

.people__main-image-container {
  position: relative; }

.people__main-image {
  width: 100vw;
  margin-left: calc((100vw - 100%) / -2);
  background: url("assets/images/our-team-image.jpg");
  margin-top: 90px;
  background-position-y: center;
  background-position-x: 30%;
  background-size: cover;
  background-repeat: no-repeat; }
  @media all and (min-width: 37.5em) {
    .people__main-image {
      margin-top: 90px;
      background-position: center; } }
  @media all and (min-width: 56.25em) {
    .people__main-image {
      margin-top: 72px;
      background-position: top left; } }
  .people__main-image:after {
    content: close-quote;
    display: block;
    padding-bottom: 170.6666667%; }
    @media all and (min-width: 37.5em) {
      .people__main-image:after {
        padding-bottom: 83.3333333%; } }
    @media all and (min-width: 56.25em) {
      .people__main-image:after {
        padding-bottom: 45.7142857%; } }

.people__core-values {
  background: #E66441;
  font-family: "Pathway Gothic One", sans-serif;
  position: absolute;
  width: calc(50px + ((100% - 55px) / 12 * 11));
  right: 0;
  padding: 24px 32px 50px 16px;
  bottom: calc(100%);
  transform: translateY(280px); }
  @media all and (min-width: 37.5em) {
    .people__core-values {
      width: calc(56px + ((100% - 88px) / 12 * 7));
      padding: 30px 30px 95px;
      right: 0;
      bottom: calc(100%);
      transform: translateY(380px); } }
  @media all and (min-width: 56.25em) {
    .people__core-values {
      width: calc(120px + ((100% - 264px) / 12 * 6));
      padding: 48px 48px 120px;
      right: 12px;
      transform: none;
      bottom: 150px; } }
  .people__core-values__header {
    line-height: 1;
    text-transform: uppercase; }
    .people__core-values__header h3 {
      font-weight: 400;
      line-height: 1;
      font-size: 40px;
      margin-top: 24px; }
      @media all and (min-width: 37.5em) {
        .people__core-values__header h3 {
          margin-top: 20px;
          font-size: 60px; } }
      @media all and (min-width: 56.25em) {
        .people__core-values__header h3 {
          font-size: 80px;
          margin-top: 24px; } }
    @media all and (min-width: 37.5em) {
      .people__core-values__header p {
        font-size: 20px; } }
    @media all and (min-width: 56.25em) {
      .people__core-values__header p {
        font-size: 24px; } }
  .people__core-values__title {
    text-transform: uppercase;
    line-height: 1;
    margin-top: 38px;
    font-size: 40px; }
    @media all and (min-width: 37.5em) {
      .people__core-values__title {
        margin-top: 54px;
        font-size: 48px; } }
    .people__core-values__title span:first-child {
      display: inline-block;
      width: 65px; }
  .people__core-values__value {
    font-family: "Cardo", serif;
    margin-top: 16px;
    margin-left: 65px; }
    @media all and (min-width: 37.5em) {
      .people__core-values__value {
        font-size: 20px;
        margin-top: 12px;
        margin-left: 65px; } }

.people__careers {
  position: relative;
  padding-top: 373px;
  padding-left: calc(5px + ((100% - 55px) / 12 * 2));
  padding-bottom: 90px; }
  @media all and (min-width: 37.5em) {
    .people__careers {
      padding-top: 480px;
      padding-left: calc(16px + ((100% - 88px) / 12 * 2));
      padding-bottom: 96px; } }
  @media all and (min-width: 56.25em) {
    .people__careers {
      padding-top: 72px;
      padding-left: calc(24px + ((100% - 264px) / 12 * 2));
      padding-bottom: 150px; } }
  .people__careers h2 {
    font-family: "Pathway Gothic One", sans-serif;
    font-weight: 400;
    font-size: 40px;
    text-transform: uppercase;
    color: #D7C18A;
    line-height: 1; }
    @media all and (min-width: 37.5em) {
      .people__careers h2 {
        font-size: 60px; } }
    @media all and (min-width: 56.25em) {
      .people__careers h2 {
        font-size: 80px; } }
  .people__careers > p {
    font-weight: 400;
    font-size: 1rem;
    color: #F9F4EC;
    line-height: 1.5;
    margin-top: 40px;
    width: calc(45px + ((100% - 55px) / 12 * 10)); }
    @media all and (min-width: 37.5em) {
      .people__careers > p {
        line-height: 1.6;
        font-size: 20px; } }
    @media all and (min-width: 37.5em) {
      .people__careers > p {
        margin-top: 32px;
        width: calc(40px + ((100% - 72px) / 10 * 6)); } }
    @media all and (min-width: 56.25em) {
      .people__careers > p {
        width: calc(72px + ((100% - 216px) / 10 * 4));
        margin-top: 60px; } }
  .people__careers .animated-link {
    margin-top: 40px; }
    @media all and (min-width: 37.5em) {
      .people__careers .animated-link {
        margin-top: 64px; } }
    @media all and (min-width: 56.25em) {
      .people__careers .animated-link {
        margin-top: 64px; } }

@media all and (min-width: 37.5em) {
  .people__team {
    padding-left: calc(16px + ((100% - 88px) / 12 * 2));
    padding-right: calc(16px + ((100% - 88px) / 12 * 2)); } }

@media all and (min-width: 56.25em) {
  .people__team {
    padding-left: calc(24px + ((100% - 264px) / 12 * 2));
    padding-right: 0;
    display: flex;
    flex-wrap: wrap; } }

.people__team h2 {
  font-family: "Pathway Gothic One", sans-serif;
  font-weight: 400;
  font-size: 40px;
  text-transform: uppercase;
  color: #fff;
  line-height: 1;
  margin-top: 80px;
  margin-bottom: 56px;
  font-size: 40px;
  text-align: center; }
  @media all and (min-width: 37.5em) {
    .people__team h2 {
      font-size: 60px; } }
  @media all and (min-width: 56.25em) {
    .people__team h2 {
      font-size: 80px; } }
  @media all and (min-width: 37.5em) {
    .people__team h2 {
      margin-top: 100px;
      margin-bottom: 56px;
      width: 100%; } }
  @media all and (min-width: 56.25em) {
    .people__team h2 {
      text-align: left; } }

.people__team__box-1 {
  font-weight: 400;
  font-size: 1rem;
  color: #F9F4EC;
  line-height: 1.5;
  width: calc(55px + (100% - 55px));
  padding: 0 calc(10px + ((100% - 55px) / 12 * 1.5)); }
  @media all and (min-width: 37.5em) {
    .people__team__box-1 {
      line-height: 1.6;
      font-size: 20px; } }
  @media all and (min-width: 37.5em) {
    .people__team__box-1 {
      width: calc(56px + (100% - 56px));
      padding: 0 calc(((100% - 56px) / 8 * 1)); } }
  @media all and (min-width: 56.25em) {
    .people__team__box-1 {
      width: calc(72px + ((100% - 216px) / 10 * 4));
      margin-right: calc(((100% - 216px) / 10 * 1));
      padding: 0; } }

.people__team__box-2 {
  font-weight: 400;
  font-size: 1rem;
  color: #F9F4EC;
  line-height: 1.5;
  margin-bottom: 80px;
  width: calc(55px + (100% - 55px));
  padding: 0 calc(10px + ((100% - 55px) / 12 * 1.5)); }
  @media all and (min-width: 37.5em) {
    .people__team__box-2 {
      line-height: 1.6;
      font-size: 20px; } }
  .people__team__box-2 .animated-link {
    margin-top: 56px; }
  @media all and (min-width: 37.5em) {
    .people__team__box-2 {
      width: calc(56px + (100% - 56px));
      padding: 0 calc(((100% - 56px) / 8 * 1));
      margin-bottom: 160px; }
      .people__team__box-2 .animated-link {
        margin-top: 90px; } }
  @media all and (min-width: 56.25em) {
    .people__team__box-2 {
      width: calc(72px + ((100% - 216px) / 10 * 4));
      margin-bottom: 200px;
      margin-top: 56px;
      padding: 0; }
      .people__team__box-2 .animated-link {
        margin-top: 88px; } }

.people__grid {
  padding: 0 calc(((100% - 55px) / 12 * 1)) 88px;
  position: relative; }
  @media all and (min-width: 37.5em) {
    .people__grid {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0 8px 100px; } }
  @media all and (min-width: 56.25em) {
    .people__grid {
      padding: 0 24px 168px; } }
  @media all and (min-width: 56.25em) {
    .people__grid:before {
      content: close-quote;
      width: 100%;
      height: calc(100% + 40px);
      background: #073255;
      display: block;
      top: -20px;
      left: 0;
      z-index: -1;
      position: absolute; } }
  .people__grid__item {
    display: flex;
    width: 100%;
    cursor: pointer; }
    @media all and (min-width: 37.5em) {
      .people__grid__item {
        width: 50%; } }
    @media all and (min-width: 56.25em) {
      .people__grid__item {
        width: 33.33%; } }
    @media all and (max-width: 37.49em) {
      .people__grid__item:nth-child(even) .people__grid__item__image {
        order: 2; } }
    @media all and (max-width: 37.49em) {
      .people__grid__item:nth-child(even) .animated-link {
        transform: scaleX(1); } }
    @media all and (min-width: 37.5em) and (max-width: 56.24em) {
      .people__grid__item:nth-child(4n) .people__grid__item__image, .people__grid__item:nth-child(4n - 1) .people__grid__item__image {
        order: 2; } }
    @media all and (min-width: 37.5em) and (max-width: 56.24em) {
      .people__grid__item:nth-child(4n) .animated-link, .people__grid__item:nth-child(4n - 1) .animated-link {
        transform: scaleX(1); } }
    @media all and (min-width: 56.25em) {
      .people__grid__item:nth-child(6n) .people__grid__item__image, .people__grid__item:nth-child(6n - 1) .people__grid__item__image, .people__grid__item:nth-child(6n-2) .people__grid__item__image {
        order: 2; } }
    @media all and (min-width: 56.25em) {
      .people__grid__item:nth-child(6n) .animated-link, .people__grid__item:nth-child(6n - 1) .animated-link, .people__grid__item:nth-child(6n-2) .animated-link {
        transform: scaleX(1); } }
    .people__grid__item__image {
      overflow: hidden;
      position: relative;
      width: 50%; }
      .people__grid__item__image:after {
        content: close-quote;
        display: block;
        padding-bottom: 100%;
        width: 100%; }
      .people__grid__item__image img {
        position: absolute;
        width: 100%; }
    .people__grid__item__content {
      width: 50%;
      padding-left: 18px;
      padding-right: 18px; }
      @media all and (min-width: 56.25em) {
        .people__grid__item__content {
          padding-left: 24px;
          padding-right: 24px; } }
      .people__grid__item__content .animated-link {
        transform: scaleX(-1); }
    .people__grid__item__name {
      margin-top: 32px;
      font-family: "PT Sans Narrow", sans-serif;
      text-transform: uppercase;
      color: #D7C18A; }
      @media all and (min-width: 56.25em) {
        .people__grid__item__name {
          margin-top: 15px; } }
      @media all and (min-width: 70.25em) {
        .people__grid__item__name {
          margin-top: 32px; } }
    @media all and (min-width: 56.25em) {
      .people__grid__item__position {
        font-size: 20px; } }

/**
 * Grid Settings
 **/
/**
 * Viewports
 **/
/*
@function verticalSpace($color: false, $viewport: mobile) {
	@if(map-has-key($verticalSpaces, $color) and map-has-key($mqBreakpoints, $viewport)) {
		@return pxToRem(map-get(map-get($verticalSpaces, $color), $viewport));
	} @else {
		@return 0;
	}
}*/
/**
 * Inspired from https://github.com/guardian/sass-mq.git
 */
/**
 * // To enable support for browsers that do not support @media queries,
 * (IE <= 8, Firefox <= 3, Opera <= 9) set $mqResponsive to false
 * Create a separate stylesheet served exclusively to these browsers,
 * meaning @media queries will be rasterized, relying on the cascade itself
 */
/**
 * Name your breakpoints in a way that creates a ubiquitous language
 * across team members. It will improve communication between
 * stakeholders, designers, developers, and testers.
 */
/**
 * Define the breakpoint from the $mqBreakpoints list that should
 * be used as the target width when outputting a static stylesheet
 * (i.e. when $mqResponsive is set to 'false').
 */
/**
 * If you want to display the currently active breakpoint in the top
 * right corner of your site during development, add the breakpoints
 * to this list, ordered by width, e.g. (mobile, tablet, desktop).
 */
/**
 * Media Query mixin
 * Usage:
 * .element {
 *     @include mq($from: mobile) {
 *         color: red;
 *     }
 *     @include mq($to: tablet) {
 *         color: blue;
 *     }
 *     @include mq(mobile, tablet) {
 *         color: green;
 *     }
 *     @include mq($from: tablet, $and: '(orientation: landscape)') {
 *         color: teal;
 *     }
 *     @include mq(950px) {
 *         color: hotpink;
 *     }
 * }
 */
/**
 * Add a breakpoint
 * Usage: $mqBreakpoints: mqAddBreakpoint(tvscreen, 1920px);
 */
/**
 * Create JSON string of map of breakpoints
 */
/**
 * Create JSON string of single breakpoint
 */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
  width: 100vw;
  height: 100vh;
  background: rgba(7, 50, 85, 0.7);
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; }
  .overlay .modal {
    background: #021629;
    margin-top: 0;
    width: calc(55px + (100% - 55px));
    position: relative;
    padding: 55px calc(5px + ((100% - 55px) / 12 * 1)); }
    @media all and (min-width: 37.5em) {
      .overlay .modal {
        width: calc(72px + ((100% - 88px) / 12 * 10));
        margin: 60px calc(8px + ((100% - 88px) / 12 * 1));
        padding: 56px calc(((100% - 72px) / 10 * 1)); } }
    @media all and (min-width: 56.25em) {
      .overlay .modal {
        width: calc(240px + ((100% - 264px) / 12 * 11));
        padding: 128px calc(((100% - 240px) / 11 * 1));
        margin-left: calc(((100% - 264px) / 12 * 0.5));
        margin-right: calc(((100% - 264px) / 12 * 0.5));
        display: flex; } }
    @media all and (min-width: 56.25em) {
      .overlay .modal__left {
        width: calc(72px + ((100% - 192px) / 9 * 4));
        flex: 0 0 auto; } }
    @media all and (min-width: 56.25em) {
      .overlay .modal__right {
        margin-left: calc(24px + ((100% - 264px) / 12 * 1));
        width: calc(72px + ((100% - 192px) / 9 * 4));
        flex: 0 0 auto; } }
    .overlay .modal__close {
      position: absolute;
      right: calc(((100% - 55px) / 12 * 1));
      top: 20px;
      right: 20px;
      width: 21px;
      height: 21px;
      transition: transform 0.3s ease; }
      @media all and (min-width: 37.5em) {
        .overlay .modal__close {
          top: 40px;
          right: 40px; } }
      @media all and (min-width: 56.25em) {
        .overlay .modal__close {
          top: 50px;
          right: 50px; } }
      .overlay .modal__close:after, .overlay .modal__close:before {
        width: 30px;
        height: 1px;
        background: #F9F4EC;
        top: 11px;
        left: -4px;
        content: close-quote;
        position: absolute;
        transition: background-color 0.3s ease, transform 0.3s ease; }
      .overlay .modal__close:after {
        transform: rotate(-45deg); }
      .overlay .modal__close:before {
        transform: rotate(45deg); }
      .overlay .modal__close:hover {
        transform: scale(0.8); }
        .overlay .modal__close:hover:before {
          background-color: #D7C18A; }
        .overlay .modal__close:hover:after {
          background-color: #D7C18A; }
    .overlay .modal__name {
      font-family: "Pathway Gothic One", sans-serif;
      font-weight: 400;
      font-size: 40px;
      text-transform: uppercase;
      color: #D7C18A;
      line-height: 1; }
      @media all and (min-width: 37.5em) {
        .overlay .modal__name {
          font-size: 60px; } }
      @media all and (min-width: 56.25em) {
        .overlay .modal__name {
          font-size: 80px; } }
    .overlay .modal__position {
      font-family: "PT Sans Narrow", sans-serif;
      text-transform: uppercase;
      margin-top: 12px;
      font-size: 24px; }
      @media all and (min-width: 37.5em) {
        .overlay .modal__position {
          margin-top: 16px; } }
    .overlay .modal__image-container {
      position: relative;
      width: calc(35px + ((100% - 45px) / 10 * 8));
      margin-top: 8px; }
      @media all and (min-width: 37.5em) {
        .overlay .modal__image-container {
          width: calc(40px + ((100% - 48px) / 7 * 6));
          margin-top: 30px; } }
      @media all and (min-width: 56.25em) {
        .overlay .modal__image-container {
          margin-top: 0;
          width: 100%; } }
      .overlay .modal__image-container.hide-on-mobile {
        display: none; }
        @media all and (min-width: 56.25em) {
          .overlay .modal__image-container.hide-on-mobile {
            display: block; } }
      @media all and (min-width: 56.25em) {
        .overlay .modal__image-container.hide-on-desktop {
          display: none; } }
      .overlay .modal__image-container:after {
        display: block;
        content: close-quote;
        padding-bottom: 123.2758621%; }
    .overlay .modal__image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%; }
    .overlay .modal__description {
      font-weight: 400;
      font-size: 1rem;
      color: #F9F4EC;
      line-height: 1.5;
      margin-top: 32px;
      width: calc(45px + (100% - 45px)); }
      @media all and (min-width: 37.5em) {
        .overlay .modal__description {
          line-height: 1.6;
          font-size: 20px; } }
      @media all and (min-width: 37.5em) {
        .overlay .modal__description {
          width: calc(40px + ((100% - 48px) / 7 * 6));
          margin-top: 60px; } }
      @media all and (min-width: 56.25em) {
        .overlay .modal__description {
          width: 100%;
          margin-top: 40px; } }
    .overlay .modal__contact {
      margin-top: 40px;
      font-family: "PT Sans Narrow", sans-serif;
      font-size: 20px;
      text-transform: uppercase; }
      .overlay .modal__contact__item {
        display: flex;
        flex-wrap: wrap; }
        .overlay .modal__contact__item:last-child {
          margin-top: 8px; }
        @media all and (min-width: 37.5em) {
          .overlay .modal__contact__item {
            flex-wrap: nowrap; } }
      .overlay .modal__contact__label {
        width: 100%;
        color: #D7C18A; }
        @media all and (min-width: 37.5em) {
          .overlay .modal__contact__label {
            width: 77px; } }

/**
 * Grid Settings
 **/
/**
 * Viewports
 **/
/*
@function verticalSpace($color: false, $viewport: mobile) {
	@if(map-has-key($verticalSpaces, $color) and map-has-key($mqBreakpoints, $viewport)) {
		@return pxToRem(map-get(map-get($verticalSpaces, $color), $viewport));
	} @else {
		@return 0;
	}
}*/
/**
 * Inspired from https://github.com/guardian/sass-mq.git
 */
/**
 * // To enable support for browsers that do not support @media queries,
 * (IE <= 8, Firefox <= 3, Opera <= 9) set $mqResponsive to false
 * Create a separate stylesheet served exclusively to these browsers,
 * meaning @media queries will be rasterized, relying on the cascade itself
 */
/**
 * Name your breakpoints in a way that creates a ubiquitous language
 * across team members. It will improve communication between
 * stakeholders, designers, developers, and testers.
 */
/**
 * Define the breakpoint from the $mqBreakpoints list that should
 * be used as the target width when outputting a static stylesheet
 * (i.e. when $mqResponsive is set to 'false').
 */
/**
 * If you want to display the currently active breakpoint in the top
 * right corner of your site during development, add the breakpoints
 * to this list, ordered by width, e.g. (mobile, tablet, desktop).
 */
/**
 * Media Query mixin
 * Usage:
 * .element {
 *     @include mq($from: mobile) {
 *         color: red;
 *     }
 *     @include mq($to: tablet) {
 *         color: blue;
 *     }
 *     @include mq(mobile, tablet) {
 *         color: green;
 *     }
 *     @include mq($from: tablet, $and: '(orientation: landscape)') {
 *         color: teal;
 *     }
 *     @include mq(950px) {
 *         color: hotpink;
 *     }
 * }
 */
/**
 * Add a breakpoint
 * Usage: $mqBreakpoints: mqAddBreakpoint(tvscreen, 1920px);
 */
/**
 * Create JSON string of map of breakpoints
 */
/**
 * Create JSON string of single breakpoint
 */
.apply-thank-you {
  height: calc(100vh - 64px - 94px); }
  @media all and (min-width: 37.5em) {
    .apply-thank-you {
      height: calc(100vh - 79px - 94px); } }
  @media all and (min-width: 56.25em) {
    .apply-thank-you {
      height: calc(100vh - 103px - 140px); } }
  .apply-thank-you .apply__header {
    background: none; }

.apply {
  padding-top: 60px;
  position: relative; }
  @media all and (min-width: 37.5em) {
    .apply {
      padding-top: 84px; } }
  @media all and (min-width: 56.25em) {
    .apply {
      padding-top: 124px; } }
  .apply:before {
    content: close-quote;
    display: block;
    width: 100vw;
    height: calc(100% + 120px);
    left: calc((100vw - 100% ) / -2);
    top: -120px;
    background: #021629;
    position: absolute;
    z-index: -2; }
  .apply__header {
    background: #E66441;
    width: calc(45px + ((100% - 55px) / 12 * 10));
    margin-left: calc(5px + ((100% - 55px) / 12 * 1));
    text-align: center;
    padding: 24px 0; }
    @media all and (min-width: 37.5em) {
      .apply__header {
        width: calc(72px + ((100% - 88px) / 12 * 10));
        margin-left: calc(8px + ((100% - 88px) / 12 * 1));
        padding: 40px 0; } }
    @media all and (min-width: 56.25em) {
      .apply__header {
        width: calc(216px + ((100% - 264px) / 12 * 8));
        margin-left: calc(24px + ((100% - 264px) / 12 * 2));
        padding: 48px 0; } }
    .apply__header h1 {
      font-family: "Pathway Gothic One", sans-serif;
      font-weight: 400;
      font-size: 40px;
      text-transform: uppercase;
      color: #fff;
      line-height: 1; }
      @media all and (min-width: 37.5em) {
        .apply__header h1 {
          font-size: 60px; } }
      @media all and (min-width: 56.25em) {
        .apply__header h1 {
          font-size: 80px; } }
    .apply__header p {
      font-weight: 400;
      font-size: 1rem;
      color: #F9F4EC;
      line-height: 1.5;
      margin-top: 32px;
      padding: 0 calc(5px + ((100% - 45px) / 10 * 1)); }
      @media all and (min-width: 37.5em) {
        .apply__header p {
          line-height: 1.6;
          font-size: 20px; } }
      @media all and (min-width: 37.5em) {
        .apply__header p {
          padding: 0 calc(((100% - 88px) / 12 * 1)); } }
      @media all and (min-width: 56.25em) {
        .apply__header p {
          padding: 0 calc(24px + ((100% - 264px) / 12 * 1.5)); } }
  .apply .form-wrapper {
    background: #fff;
    color: #021629;
    margin-top: 90px;
    padding: 36px calc(((100% - 55px) / 12 * 1)); }
    @media all and (min-width: 37.5em) {
      .apply .form-wrapper {
        padding: 65px calc(8px + ((100% - 88px) / 12 * 2));
        margin-top: 160px; } }
    @media all and (min-width: 56.25em) {
      .apply .form-wrapper {
        padding: 120px calc(24px + ((100% - 264px) / 12 * 2));
        margin-top: 220px; } }
  .apply .form .bot-field-wrapper {
    display: none; }
  .apply .form__title {
    font-family: "Pathway Gothic One", sans-serif;
    font-weight: 400;
    font-size: 40px;
    text-transform: uppercase;
    color: #021629;
    line-height: 1; }
    @media all and (min-width: 37.5em) {
      .apply .form__title {
        font-size: 60px; } }
    @media all and (min-width: 56.25em) {
      .apply .form__title {
        font-size: 80px; } }
  .apply .form__subtitle {
    margin-top: 32px; }
    @media all and (min-width: 37.5em) {
      .apply .form__subtitle {
        margin-top: 8px;
        width: calc(64px + ((100% - 88px) / 12 * 9)); } }
    @media all and (min-width: 56.25em) {
      .apply .form__subtitle {
        margin-top: 24px;
        width: calc(168px + (100% - 168px)); } }
  .apply .form__prefill {
    margin-top: 32px; }
    @media all and (min-width: 37.5em) {
      .apply .form__prefill {
        margin-top: 42px; } }
    @media all and (min-width: 56.25em) {
      .apply .form__prefill {
        margin-top: 48px; } }
  .apply .form__section {
    margin-top: 40px; }
    @media all and (min-width: 37.5em) {
      .apply .form__section {
        margin-top: 80px; } }
    @media all and (min-width: 56.25em) {
      .apply .form__section {
        margin-top: 94px; } }
    .apply .form__section:first-child {
      margin-top: 40px; }
      @media all and (min-width: 56.25em) {
        .apply .form__section:first-child {
          margin-top: 64px; } }
    .apply .form__section--experience {
      margin-top: 60px; }
      @media all and (min-width: 37.5em) {
        .apply .form__section--experience {
          margin-top: 100px; } }
      @media all and (min-width: 56.25em) {
        .apply .form__section--experience {
          margin-top: 140px; } }
    .apply .form__section--file {
      margin-top: 67px; }
      @media all and (min-width: 37.5em) {
        .apply .form__section--file {
          margin-top: 88px; } }
      @media all and (min-width: 56.25em) {
        .apply .form__section--file {
          margin-top: 136px; } }
    .apply .form__section__title {
      text-transform: uppercase;
      margin-bottom: 16px; }
      @media all and (min-width: 37.5em) {
        .apply .form__section__title {
          font-size: 20px;
          margin-bottom: 32px; } }
      @media all and (min-width: 56.25em) {
        .apply .form__section__title {
          margin-bottom: 48px; } }
    @media all and (min-width: 37.5em) {
      .apply .form__section__content {
        display: flex;
        flex-wrap: wrap; } }
    @media all and (min-width: 56.25em) {
      .apply .form__section__content {
        justify-content: space-between; } }
  .apply .form__input-wrapper {
    width: calc(45px + (100% - 45px));
    position: relative;
    margin-top: 26px; }
    @media all and (min-width: 37.5em) {
      .apply .form__input-wrapper {
        width: calc(56px + (100% - 56px));
        margin-top: 56px; } }
    @media all and (min-width: 56.25em) {
      .apply .form__input-wrapper {
        width: calc(72px + ((100% - 168px) / 8 * 3.5));
        margin-top: 78px; } }
    .apply .form__input-wrapper:nth-child(1) {
      margin-top: 0; }
    @media all and (min-width: 56.25em) {
      .apply .form__input-wrapper:nth-child(2) {
        margin-top: 0; } }
    .apply .form__input-wrapper:after {
      display: block;
      width: 100%;
      content: close-quote;
      position: absolute;
      bottom: 0;
      height: 1px;
      background-color: #021629; }
    .apply .form__input-wrapper--full-width {
      width: 100%; }
  .apply .form__submission-complete {
    font-family: "PT Sans Narrow", sans-serif;
    color: #E66441;
    text-transform: uppercase;
    opacity: 0; }
  .apply .form__submission-complete-wrapper {
    display: flex;
    margin-top: 24px;
    justify-content: center; }
    @media all and (min-width: 37.5em) {
      .apply .form__submission-complete-wrapper {
        margin-top: 32px; } }
  .apply .form__input {
    border: none;
    display: block;
    outline: none;
    padding: 0;
    width: 100%; }
    @media all and (min-width: 37.5em) {
      .apply .form__input {
        font-size: 20px; } }
  .apply .form__radio-wrapper {
    display: flex;
    align-items: center;
    margin-right: 0;
    margin-top: 8px;
    width: 100%; }
    @media all and (min-width: 37.5em) {
      .apply .form__radio-wrapper {
        width: auto;
        margin-top: 0;
        margin-right: calc(((100% - 56px) / 8 * 1)); } }
    @media all and (min-width: 56.25em) {
      .apply .form__radio-wrapper {
        margin-right: calc(((100% - 168px) / 8 * 1)); } }
    .apply .form__radio-wrapper:last-child {
      margin-right: 0; }
    .apply .form__radio-wrapper input {
      display: block;
      margin-right: 10px; }
    .apply .form__radio-wrapper label {
      display: block; }
  .apply .form__radio-group {
    margin-top: 36px;
    display: flex;
    flex-wrap: wrap;
    width: 100%; }
    @media all and (min-width: 37.5em) {
      .apply .form__radio-group {
        margin-top: 80px;
        font-size: 20px; } }
    @media all and (min-width: 56.25em) {
      .apply .form__radio-group {
        margin-top: 100px; } }
    .apply .form__radio-group__title {
      margin-bottom: 8px;
      width: 100%; }
      @media all and (min-width: 37.5em) {
        .apply .form__radio-group__title {
          margin-bottom: 32px; } }
      @media all and (min-width: 56.25em) {
        .apply .form__radio-group__title {
          width: auto;
          margin-bottom: 0;
          margin-right: calc(((100% - 168px) / 8 * 0.5)); } }
  .apply .form__submit-wrapper {
    display: flex;
    margin-top: 80px; }
    @media all and (min-width: 37.5em) {
      .apply .form__submit-wrapper {
        margin-top: 100px; } }
    @media all and (min-width: 56.25em) {
      .apply .form__submit-wrapper {
        justify-content: flex-end; } }
  .apply .form__submit {
    background: none;
    border: none;
    padding: 0;
    display: block; }
  .apply .form__sending-data-info {
    color: #E66441;
    text-transform: uppercase;
    font-family: "Pathway Gothic One", sans-serif; }
  .apply .form__file {
    font-size: 16px;
    display: block;
    cursor: pointer;
    width: 100%; }
    @media all and (min-width: 37.5em) {
      .apply .form__file {
        font-size: 20px; } }
    .apply .form__file:not(:first-child) {
      margin-top: 48px; }
    .apply .form__file__input {
      display: none; }
    .apply .form__file__value {
      font-family: "PT Sans Narrow", sans-serif;
      color: #E66441;
      text-transform: uppercase;
      margin-left: calc(((100% - 55px) / 12 * 1)); }
      @media all and (min-width: 37.5em) {
        .apply .form__file__value {
          margin-left: calc(((100% - 88px) / 12 * 1)); } }
      @media all and (min-width: 56.25em) {
        .apply .form__file__value {
          margin-left: calc(((100% - 264px) / 12 * 1)); } }

/**
 * Grid Settings
 **/
/**
 * Viewports
 **/
/*
@function verticalSpace($color: false, $viewport: mobile) {
	@if(map-has-key($verticalSpaces, $color) and map-has-key($mqBreakpoints, $viewport)) {
		@return pxToRem(map-get(map-get($verticalSpaces, $color), $viewport));
	} @else {
		@return 0;
	}
}*/
/**
 * Inspired from https://github.com/guardian/sass-mq.git
 */
/**
 * // To enable support for browsers that do not support @media queries,
 * (IE <= 8, Firefox <= 3, Opera <= 9) set $mqResponsive to false
 * Create a separate stylesheet served exclusively to these browsers,
 * meaning @media queries will be rasterized, relying on the cascade itself
 */
/**
 * Name your breakpoints in a way that creates a ubiquitous language
 * across team members. It will improve communication between
 * stakeholders, designers, developers, and testers.
 */
/**
 * Define the breakpoint from the $mqBreakpoints list that should
 * be used as the target width when outputting a static stylesheet
 * (i.e. when $mqResponsive is set to 'false').
 */
/**
 * If you want to display the currently active breakpoint in the top
 * right corner of your site during development, add the breakpoints
 * to this list, ordered by width, e.g. (mobile, tablet, desktop).
 */
/**
 * Media Query mixin
 * Usage:
 * .element {
 *     @include mq($from: mobile) {
 *         color: red;
 *     }
 *     @include mq($to: tablet) {
 *         color: blue;
 *     }
 *     @include mq(mobile, tablet) {
 *         color: green;
 *     }
 *     @include mq($from: tablet, $and: '(orientation: landscape)') {
 *         color: teal;
 *     }
 *     @include mq(950px) {
 *         color: hotpink;
 *     }
 * }
 */
/**
 * Add a breakpoint
 * Usage: $mqBreakpoints: mqAddBreakpoint(tvscreen, 1920px);
 */
/**
 * Create JSON string of map of breakpoints
 */
/**
 * Create JSON string of single breakpoint
 */
.news-overview {
  padding: 60px calc(5px + ((100% - 55px) / 12 * 1)); }
  @media all and (min-width: 37.5em) {
    .news-overview {
      padding: 84px calc(16px + ((100% - 88px) / 12 * 2)); } }
  @media all and (min-width: 56.25em) {
    .news-overview {
      padding: 124px calc(24px + ((100% - 264px) / 12 * 1)); } }
  .news-overview__title {
    font-family: "Pathway Gothic One", sans-serif;
    font-weight: 400;
    font-size: 40px;
    text-transform: uppercase;
    color: #D7C18A;
    line-height: 1;
    position: relative;
    z-index: 1; }
    @media all and (min-width: 37.5em) {
      .news-overview__title {
        font-size: 60px; } }
    @media all and (min-width: 56.25em) {
      .news-overview__title {
        font-size: 80px; } }
  @media all and (min-width: 37.5em) {
    .news-overview__teaser-wrapper {
      display: flex;
      flex-wrap: wrap; } }

.news-teaser {
  width: calc(55px + (100% - 55px));
  padding: 0;
  margin-top: 4rem; }
  @media all and (min-width: 37.5em) {
    .news-teaser {
      width: calc(56px + (100% - 56px));
      margin-top: 5.625rem; }
      .news-teaser:nth-child(2n) {
        margin-right: 0; } }
  @media all and (min-width: 56.25em) {
    .news-teaser {
      width: calc(96px + ((100% - 216px) / 10 * 5));
      margin-right: 24px;
      margin-top: 6.875rem;
      padding: 0; }
      .news-teaser:nth-child(odd) {
        padding-left: calc(((100% - 264px) / 12 * 0.5)); }
      .news-teaser:nth-child(even) {
        padding-right: calc(((100% - 264px) / 12 * 0.5)); } }
  .news-teaser__image {
    position: relative;
    overflow: hidden;
    margin-bottom: 1.25rem; }
    .news-teaser__image:after {
      content: close-quote;
      display: block;
      width: 100%;
      padding-bottom: 65.0234742%;
      background: darkgrey; }
    @media all and (min-width: 56.25em) {
      .news-teaser__image {
        margin-bottom: 1.5rem; } }
    .news-teaser__image img {
      position: absolute;
      top: 0;
      width: 100%; }
  .news-teaser__title {
    font-family: "Pathway Gothic One", sans-serif;
    font-size: 2rem;
    font-weight: normal;
    text-transform: uppercase;
    line-height: normal; }
    @media all and (min-width: 37.5em) {
      .news-teaser__title {
        font-size: 3rem; } }
    @media all and (min-width: 56.25em) {
      .news-teaser__title {
        font-size: 3rem; } }
  .news-teaser__date {
    margin-top: 0.75rem;
    font-family: "Pathway Gothic One", sans-serif;
    text-transform: uppercase; }
    @media all and (min-width: 37.5em) {
      .news-teaser__date {
        font-size: 1.25rem; } }
    @media all and (min-width: 56.25em) {
      .news-teaser__date {
        font-size: 1.25rem; } }
  .news-teaser__excerpt {
    font-weight: 400;
    font-size: 1rem;
    color: #F9F4EC;
    line-height: 1.5;
    margin-top: 12px; }
    @media all and (min-width: 37.5em) {
      .news-teaser__excerpt {
        line-height: 1.6;
        font-size: 20px; } }
    @media all and (min-width: 56.25em) {
      .news-teaser__excerpt {
        margin-top: 12px; } }
  .news-teaser .animated-link {
    margin-top: 16px; }

/**
 * Grid Settings
 **/
/**
 * Viewports
 **/
/*
@function verticalSpace($color: false, $viewport: mobile) {
	@if(map-has-key($verticalSpaces, $color) and map-has-key($mqBreakpoints, $viewport)) {
		@return pxToRem(map-get(map-get($verticalSpaces, $color), $viewport));
	} @else {
		@return 0;
	}
}*/
/**
 * Inspired from https://github.com/guardian/sass-mq.git
 */
/**
 * // To enable support for browsers that do not support @media queries,
 * (IE <= 8, Firefox <= 3, Opera <= 9) set $mqResponsive to false
 * Create a separate stylesheet served exclusively to these browsers,
 * meaning @media queries will be rasterized, relying on the cascade itself
 */
/**
 * Name your breakpoints in a way that creates a ubiquitous language
 * across team members. It will improve communication between
 * stakeholders, designers, developers, and testers.
 */
/**
 * Define the breakpoint from the $mqBreakpoints list that should
 * be used as the target width when outputting a static stylesheet
 * (i.e. when $mqResponsive is set to 'false').
 */
/**
 * If you want to display the currently active breakpoint in the top
 * right corner of your site during development, add the breakpoints
 * to this list, ordered by width, e.g. (mobile, tablet, desktop).
 */
/**
 * Media Query mixin
 * Usage:
 * .element {
 *     @include mq($from: mobile) {
 *         color: red;
 *     }
 *     @include mq($to: tablet) {
 *         color: blue;
 *     }
 *     @include mq(mobile, tablet) {
 *         color: green;
 *     }
 *     @include mq($from: tablet, $and: '(orientation: landscape)') {
 *         color: teal;
 *     }
 *     @include mq(950px) {
 *         color: hotpink;
 *     }
 * }
 */
/**
 * Add a breakpoint
 * Usage: $mqBreakpoints: mqAddBreakpoint(tvscreen, 1920px);
 */
/**
 * Create JSON string of map of breakpoints
 */
/**
 * Create JSON string of single breakpoint
 */
.news-details {
  padding: 3.75rem calc(((100% - 55px) / 12 * 1)) 2.5rem calc(((100% - 55px) / 12 * 1)); }
  .news-details__back {
    display: block;
    margin-bottom: 2rem;
    padding-left: calc(((100% - 55px) / 12 * 1)); }
    @media all and (min-width: 37.5em) {
      .news-details__back {
        padding-left: calc(((100% - 88px) / 12 * 1)); } }
    @media all and (min-width: 56.25em) {
      .news-details__back {
        padding-left: calc(((100% - 264px) / 12 * 1)); } }
  .news-details__title {
    font-family: "Pathway Gothic One", sans-serif;
    font-weight: 400;
    font-size: 40px;
    text-transform: uppercase;
    color: #D7C18A;
    line-height: 1;
    color: #073255; }
    @media all and (min-width: 37.5em) {
      .news-details__title {
        font-size: 60px; } }
    @media all and (min-width: 56.25em) {
      .news-details__title {
        font-size: 80px; } }
  .news-details__date-author {
    margin-top: 1rem; }
    @media all and (min-width: 37.5em) {
      .news-details__date-author {
        margin-top: 1rem; } }
    @media all and (min-width: 56.25em) {
      .news-details__date-author {
        display: flex;
        margin-top: 1.5rem; } }
    .news-details__date-author > div {
      font-family: "Pathway Gothic One", sans-serif;
      color: #073255;
      text-transform: uppercase;
      font-size: 1rem;
      line-height: 1; }
      @media all and (min-width: 37.5em) {
        .news-details__date-author > div {
          font-size: 1.25rem; } }
    @media all and (min-width: 37.5em) {
      .news-details__date-author > div:last-child {
        margin-top: 1rem; } }
    @media all and (min-width: 56.25em) {
      .news-details__date-author > div:last-child {
        margin-top: 0;
        margin-left: 1.875rem; } }
  .news-details__content {
    background: #fff;
    padding: 1rem;
    position: relative; }
    @media all and (min-width: 37.5em) {
      .news-details__content {
        padding: 1.5rem; } }
    .news-details__content:after {
      width: 100%;
      height: 100%;
      transform: translate(16px, 16px);
      content: close-quote;
      background: #021629;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      z-index: -1; }
      @media all and (min-width: 37.5em) {
        .news-details__content:after {
          transform: translate(24px, 24px); } }
      @media all and (min-width: 56.25em) {
        .news-details__content:after {
          transform: translate(48px, 48px); } }
  .news-details__image {
    display: block;
    position: relative;
    margin-bottom: 1rem;
    overflow: hidden;
    /*
    &:before {
      background: darkgray;
      display: block;
      content: close-quote;
      width: 100%;
      padding-bottom: 53.4013605%;

      @include mq($from: tablet) {
        padding-bottom: 52.3731588%;
      }

      @include mq($from: desktop) {
        padding-bottom: 52.1317829%;
      }

    }
    */ }
    @media all and (min-width: 37.5em) {
      .news-details__image {
        margin-bottom: 1.5rem; } }
    @media all and (min-width: 56.25em) {
      .news-details__image {
        margin-bottom: 2.5rem; } }
    .news-details__image img {
      top: 0;
      display: block;
      width: 100%; }
  .news-details__text {
    color: #073255;
    margin-top: 1rem; }
    @media all and (min-width: 37.5em) {
      .news-details__text {
        margin-top: 1.5rem; } }
    @media all and (min-width: 56.25em) {
      .news-details__text {
        margin-top: 2rem; } }
    .news-details__text a {
      text-decoration: underline;
      color: #073255;
      word-break: break-all; }
    .news-details__text p {
      font-size: 1rem;
      line-height: 1.5;
      color: #073255; }
      @media all and (min-width: 37.5em) {
        .news-details__text p {
          font-size: 1.25rem;
          line-height: 1.6; } }
      @media all and (min-width: 56.25em) {
        .news-details__text p {
          font-size: 1.25rem;
          line-height: 1.6; } }
    .news-details__text h1 {
      font-size: 2.5rem;
      font-weight: normal;
      color: #073255;
      line-height: 1;
      margin-top: 1.5rem;
      margin-bottom: 1rem;
      font-family: "Pathway Gothic One", sans-serif;
      text-transform: uppercase; }
      @media all and (min-width: 37.5em) {
        .news-details__text h1 {
          font-size: 3.75rem; } }
      @media all and (min-width: 56.25em) {
        .news-details__text h1 {
          font-size: 5rem; } }
      @media all and (min-width: 37.5em) {
        .news-details__text h1 {
          margin-top: 1.5rem; } }
      @media all and (min-width: 56.25em) {
        .news-details__text h1 {
          margin-top: 2.5rem; } }
    .news-details__text h2 {
      font-size: 2.5rem;
      font-weight: normal;
      color: #073255;
      margin-top: 1.5rem;
      margin-bottom: 1rem;
      font-family: "Pathway Gothic One", sans-serif;
      text-transform: uppercase; }
      @media all and (min-width: 37.5em) {
        .news-details__text h2 {
          font-size: 3rem; } }
      @media all and (min-width: 56.25em) {
        .news-details__text h2 {
          font-size: 2.875rem; } }
      @media all and (min-width: 37.5em) {
        .news-details__text h2 {
          margin-top: 1.5rem; } }
      @media all and (min-width: 56.25em) {
        .news-details__text h2 {
          margin-top: 2.5rem; } }
    .news-details__text h3 {
      font-size: 2rem;
      font-weight: normal;
      color: #073255;
      margin-top: 1.5rem;
      margin-bottom: 1rem;
      font-family: "Pathway Gothic One", sans-serif;
      text-transform: uppercase; }
      @media all and (min-width: 37.5em) {
        .news-details__text h3 {
          margin-top: 1.5rem; } }
      @media all and (min-width: 56.25em) {
        .news-details__text h3 {
          margin-top: 2.5rem; } }
    .news-details__text h4 {
      font-size: 1.5rem;
      font-weight: normal;
      color: #073255;
      margin-top: 1.5rem;
      margin-bottom: 1rem;
      font-family: "Pathway Gothic One", sans-serif;
      text-transform: uppercase; }
      @media all and (min-width: 37.5em) {
        .news-details__text h4 {
          margin-top: 1.5rem; } }
      @media all and (min-width: 56.25em) {
        .news-details__text h4 {
          margin-top: 2.5rem; } }
    .news-details__text h5 {
      font-size: 1.25rem;
      font-weight: normal;
      color: #073255;
      margin-top: 1.5rem;
      margin-bottom: 1rem;
      font-family: "Pathway Gothic One", sans-serif;
      text-transform: uppercase; }
      @media all and (min-width: 37.5em) {
        .news-details__text h5 {
          margin-top: 1.5rem; } }
      @media all and (min-width: 56.25em) {
        .news-details__text h5 {
          margin-top: 2.5rem; } }
    .news-details__text h6 {
      font-size: 1rem;
      font-weight: normal;
      color: #073255;
      margin-top: 1.5rem;
      margin-bottom: 1rem;
      font-family: "Pathway Gothic One", sans-serif;
      text-transform: uppercase; }
      @media all and (min-width: 37.5em) {
        .news-details__text h6 {
          margin-top: 1.5rem; } }
      @media all and (min-width: 56.25em) {
        .news-details__text h6 {
          margin-top: 2.5rem; } }
    .news-details__text blockquote {
      font-size: 1rem;
      color: #073255;
      line-height: 1.5;
      font-style: italic; }
      @media all and (min-width: 37.5em) {
        .news-details__text blockquote {
          font-size: 1.25rem;
          line-height: 1.2; } }
      @media all and (min-width: 56.25em) {
        .news-details__text blockquote {
          font-size: 1.5rem;
          line-height: 1.6; } }
  .news-details__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 5rem;
    padding: 0 calc(((100% - 55px) / 12 * 1)); }
    .news-details__buttons a:not(.inverted) {
      margin-left: auto; }
    @media all and (min-width: 37.5em) {
      .news-details__buttons {
        padding: 0 calc(((100% - 88px) / 12 * 1)); } }
    @media all and (min-width: 56.25em) {
      .news-details__buttons {
        margin-top: 7.5rem;
        padding: 0 calc(((100% - 264px) / 12 * 1)); } }

body:before {
  display: none; }

.app {
  position: relative; }

.background-lines {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -2;
  width: 100%;
  height: calc(100% + 100px);
  display: none; }
  @media all and (min-width: 56.25em) {
    .background-lines {
      display: flex;
      justify-content: space-between;
      padding: 0 calc(((100% - 264px) / 12 * 1)); } }

.background-line {
  background-color: rgba(215, 193, 138, 0.4);
  width: 1px;
  height: 100%; }
  @media all and (min-width: 56.25em) {
    .background-line--1 {
      margin-left: calc(((100% - 216px) / 10 * 0.5)); } }
  @media all and (min-width: 56.25em) {
    .background-line--3 {
      margin-right: calc(((100% - 216px) / 10 * 0.5)); } }

body:before {
  background-color: #FCF8E3;
  border-bottom: 1px solid #FBEED5;
  border-left: 1px solid #FBEED5;
  color: #C09853;
  font: small-caption;
  padding: 3px 6px;
  pointer-events: none;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 100;
  content: ':'; }
  @media all {
    body:before {
      content: "tiny ≥ 0 (0em)"; } }
  @media all {
    body:before {
      content: "mobile ≥ 0px (0em)"; } }
  @media all and (min-width: 37.5em) {
    body:before {
      content: "tablet ≥ 600px (37.5em)"; } }
  @media all and (min-width: 56.25em) {
    body:before {
      content: "desktop ≥ 900px (56.25em)"; } }

.animated-container .p1, .animated-container .ul1 {
  opacity: 0;
  transform: translateY(50px); }

body.modal-open {
  overflow: hidden;
  position: relative; }

.with-transitions {
  opacity: 0;
  transform: translateY(50px); }

/*.set-hidden-state {
  @include setHiddenState;
}*/
.set-visible-state {
  opacity: 1;
  transform: translateY(0); }

.show-grid #grid {
  background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="rgba(0, 255, 0, 0.1)" width="100%" %3E%3Crect x="0" width="calc(((100% - 55px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(5px + ((100% - 55px) / 12 * 1))" width="calc(((100% - 55px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(10px + ((100% - 55px) / 12 * 2))" width="calc(((100% - 55px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(15px + ((100% - 55px) / 12 * 3))" width="calc(((100% - 55px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(20px + ((100% - 55px) / 12 * 4))" width="calc(((100% - 55px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(25px + ((100% - 55px) / 12 * 5))" width="calc(((100% - 55px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(30px + ((100% - 55px) / 12 * 6))" width="calc(((100% - 55px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(35px + ((100% - 55px) / 12 * 7))" width="calc(((100% - 55px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(40px + ((100% - 55px) / 12 * 8))" width="calc(((100% - 55px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(45px + ((100% - 55px) / 12 * 9))" width="calc(((100% - 55px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(50px + ((100% - 55px) / 12 * 10))" width="calc(((100% - 55px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(55px + ((100% - 55px) / 12 * 11))" width="calc(((100% - 55px) / 12 * 1))" height="100%"/%3E%3C/svg%3E') no-repeat scroll;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%; }
  @media all and (min-width: 37.5em) {
    .show-grid #grid {
      background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="rgba(0, 255, 0, 0.1)" width="100%" %3E%3Crect x="0" width="calc(((100% - 88px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(8px + ((100% - 88px) / 12 * 1))" width="calc(((100% - 88px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(16px + ((100% - 88px) / 12 * 2))" width="calc(((100% - 88px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(24px + ((100% - 88px) / 12 * 3))" width="calc(((100% - 88px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(32px + ((100% - 88px) / 12 * 4))" width="calc(((100% - 88px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(40px + ((100% - 88px) / 12 * 5))" width="calc(((100% - 88px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(48px + ((100% - 88px) / 12 * 6))" width="calc(((100% - 88px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(56px + ((100% - 88px) / 12 * 7))" width="calc(((100% - 88px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(64px + ((100% - 88px) / 12 * 8))" width="calc(((100% - 88px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(72px + ((100% - 88px) / 12 * 9))" width="calc(((100% - 88px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(80px + ((100% - 88px) / 12 * 10))" width="calc(((100% - 88px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(88px + ((100% - 88px) / 12 * 11))" width="calc(((100% - 88px) / 12 * 1))" height="100%"/%3E%3C/svg%3E') no-repeat scroll; } }
  @media all and (min-width: 56.25em) {
    .show-grid #grid {
      background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="rgba(0, 255, 0, 0.1)" width="100%" %3E%3Crect x="0" width="calc(((100% - 264px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(24px + ((100% - 264px) / 12 * 1))" width="calc(((100% - 264px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(48px + ((100% - 264px) / 12 * 2))" width="calc(((100% - 264px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(72px + ((100% - 264px) / 12 * 3))" width="calc(((100% - 264px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(96px + ((100% - 264px) / 12 * 4))" width="calc(((100% - 264px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(120px + ((100% - 264px) / 12 * 5))" width="calc(((100% - 264px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(144px + ((100% - 264px) / 12 * 6))" width="calc(((100% - 264px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(168px + ((100% - 264px) / 12 * 7))" width="calc(((100% - 264px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(192px + ((100% - 264px) / 12 * 8))" width="calc(((100% - 264px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(216px + ((100% - 264px) / 12 * 9))" width="calc(((100% - 264px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(240px + ((100% - 264px) / 12 * 10))" width="calc(((100% - 264px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(264px + ((100% - 264px) / 12 * 11))" width="calc(((100% - 264px) / 12 * 1))" height="100%"/%3E%3C/svg%3E') no-repeat scroll; } }
  @media all and (min-width: 82.5em) {
    .show-grid #grid {
      background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="rgba(0, 255, 0, 0.1)" width="100%" %3E%3Crect x="0" width="calc(((100% - 264px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(24px + ((100% - 264px) / 12 * 1))" width="calc(((100% - 264px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(48px + ((100% - 264px) / 12 * 2))" width="calc(((100% - 264px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(72px + ((100% - 264px) / 12 * 3))" width="calc(((100% - 264px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(96px + ((100% - 264px) / 12 * 4))" width="calc(((100% - 264px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(120px + ((100% - 264px) / 12 * 5))" width="calc(((100% - 264px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(144px + ((100% - 264px) / 12 * 6))" width="calc(((100% - 264px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(168px + ((100% - 264px) / 12 * 7))" width="calc(((100% - 264px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(192px + ((100% - 264px) / 12 * 8))" width="calc(((100% - 264px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(216px + ((100% - 264px) / 12 * 9))" width="calc(((100% - 264px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(240px + ((100% - 264px) / 12 * 10))" width="calc(((100% - 264px) / 12 * 1))" height="100%"/%3E%3Crect x="calc(264px + ((100% - 264px) / 12 * 11))" width="calc(((100% - 264px) / 12 * 1))" height="100%"/%3E%3C/svg%3E') no-repeat scroll; } }
  @media all and (min-width: 56.25em) {
    .show-grid #grid {
      max-width: 82.5rem;
      left: 50%;
      transform: translateX(-50%); } }

.block-scroll {
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden; }

#preloader {
  width: 100vw;
  height: 100vw;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-start; }

.preloader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 200px; }
  .preloader-container.loaded img, .preloader-container.loaded .spinner {
    opacity: 0;
    transform: translateY(-20px); }
  .preloader-container img {
    width: 80px;
    display: block;
    margin-left: 15px;
    margin-bottom: 20px;
    transition: opacity 0.4s ease-out, transform 0.4s ease-out; }
  .preloader-container .spinner {
    margin: 0 auto;
    width: 90px;
    text-align: center;
    transition: opacity 0.4s ease-out 0.1s, transform 0.4s ease-out 0.1s; }
  .preloader-container .spinner > div {
    width: 16px;
    height: 16px;
    background-color: #E66441;
    margin-right: 4px;
    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1.6s infinite ease-in-out both; }
    .preloader-container .spinner > div:last-child {
      margin-right: 0; }
  .preloader-container .spinner .bounce1 {
    animation-delay: -0.42s; }
  .preloader-container .spinner .bounce2 {
    animation-delay: -0.16s; }

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0); }
  40% {
    transform: scale(0.9); } }
